import api from '../util/api';

export async function getProductsNeedPrintTags(page, search, order) {

    return (await api.get('/tags', {

        params: {
            page,
            search,
            order,
        },

    })).data;
}

export async function updatePrice(ids) {
    return await api.put('/tags/update-price', { ids });
}

export async function printTags(ids, amounts) {


    try {
        const response = await api.put('/tags/print', { ids, amounts }, {
            responseType: 'blob',
        });


        const contentType = response.headers['content-type'];

        console.log(contentType);

        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);

        const fileName = contentType === 'application/zip' ? 'etiquetas.zip' : 'etiqueta.pdf';


        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();


        window.URL.revokeObjectURL(url);

    } catch (error) {
        console.error('Error printing tags:', error);
    }
}
