import { DateTime } from 'luxon';
import tefApi from '../util/tefApi';
import { PaymentsEnum } from 'erva-doce-common';
import { extractCurrencyNumber } from '../components/FieldCurrency';

const TIPO_TRANSACAO = {
    [PaymentsEnum.CREDIT_CARD]: 10,
    [`${PaymentsEnum.CREDIT_CARD}_PARCELADO`]: 11,
    [PaymentsEnum.DEBIT_CARD]: 20,
    [`${PaymentsEnum.DEBIT_CARD}_PARCELADO`]: 22,
};

export async function createTransaction(data) {
    return (await tefApi.post('/tefgp-req', {
        comando: 'CRT',
        identificao: data.id,
        valorTotal: parseFloat(data.total).toFixed(2).replace('.', ''),
        parcelas: data.quantityInstallments,
        moeda: 0,
        tipoTransacao: TIPO_TRANSACAO[`${data.paymentType}${data.quantityInstallments === 1 ? '' : '_PARCELADO'}`],
        capAutomacao: 3,
        empresaAutomacao: 'Erva Doce Perfumaria',
        dataHoraFiscal: DateTime.now().toISO(),
        versaoInterface: 40,
        nomeAutomacao: 'Erva Doce Perfumaria - TEF',
        versaoAutomacao: 'v1.0',
    })).data;
}

export async function confirmTransaction(id, data) {
    return (await tefApi.post('/tefgp-conf', {
        comando: 'CNF',
        identificacao: id,
        adquirente: data.tefRes.adquirente,
        moeda: 0,
        parcelas: data.quantityInstallments,
        capAutomacao: 3,
        empresaAutomacao: 'Erva Doce Perfumaria',
        dataHoraFiscal: DateTime.now().toISO(),
        versaoInterface: 40,
        nomeAutomacao: 'Erva Doce Perfumaria - TEF',
        versaoAutomacao: 'v1.0',
    })).data;
}

export async function cancelTransaction(id, data) {
    return (await tefApi.post('/tefgp-cancel', {
        comando: 'CNC',
        identificacao: id,
        adquirente: data.tefRes.adquirente,
        moeda: 0,
        valorTotal: parseFloat(extractCurrencyNumber(data.total)).toFixed(2).replace('.', ''),
        parcelas: data.quantityInstallments,
        nsu: data.tefRes.nsu,
        controle: data.tefRes.controle,
        capAutomacao: 3,
        empresaAutomacao: 'Erva Doce Perfumaria',
        dataHoraFiscal: DateTime.now().toISO(),
        versaoInterface: 40,
        nomeAutomacao: 'Erva Doce Perfumaria - TEF',
        versaoAutomacao: 'v1.0',
    })).data;
}
