import {
    ENUM_SHORTCUT,
    PermissionsEnum,
    ValidationErrorRepresentativeText,
    isValidEmail,
    isValidName,
    isValidPhone,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import { extractCurrencyNumber, formatCurrency } from '../../components/FieldCurrency';
import FieldPhone from '../../components/FieldPhone';
import FieldText from '../../components/FieldText';
import FieldTextarea from '../../components/FieldTextarea';
import { Loading, LoadingSize } from '../../components/Loading';
import Picture, { PictureSize, PictureStyle } from '../../components/Picture';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as RepresentativesService from '../../services/RepresentativesService';
import { getRepresentativesRoute } from './Representatives';
import Suppliers from './Suppliers';

export default function RepresentativeForm() {
    const emptyFormData = {
        picture: null,
        name: '',
        email: '',
        phone: '',
        cellphone: '',
        observation: '',
        suppliers: [],
    };
    const emptyFormError = {
        ...emptyFormData,
        picture: undefined,
    };

    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pictureChanged, setPictureChanged] = useState(false);
    const buttonSaveRef = useRef();
    const buttonDeleteRef = useRef();
    const { uuid } = useParams();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const [loadingRepresentative, setLoadingRepresentative] = useState(!!uuid);
    const [representativeName, setRepresentativeName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const phoneInputRef = useRef();
    const navigate = useNavigate();

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchRepresentative() {
        try {
            setLoadingRepresentative(true);
            let representative = await RepresentativesService.getRepresentative(uuid);
            representative.suppliers = representative.suppliers.map((supplier) => ({
                ...supplier,
                minimumOrder: formatCurrency(`${supplier.minimumOrder}`, true) ?? '',
                quotationType: supplier.quotationType ? { id: supplier.quotationType } : null,
                responsible: supplier.responsible ? { id: supplier.responsible } : null,
            }));
            setRepresentativeName(representative.name);
            // noinspection JSCheckFunctionSignatures
            setFormData({
                ...representative,
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Representante não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch representative', e, null, title);
            }
        } finally {
            setLoadingRepresentative(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchRepresentative();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);
        if (!isValidName(formData.name)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, name: 'Digite um nome válido' }));
        }
        if (!isValidEmail(formData.email)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, email: 'Digite um email válido' }));
        }
        if (!isValidPhone(formData.phone)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, phone: 'Digite um telefone válido' }));
        }
        if (formData.cellphone?.trim() && !isValidPhone(formData.cellphone)) {
            hasError = true;
            // I18N
            // noinspection JSCheckFunctionSignatures
            setFormError((formError) => ({ ...formError, cellphone: 'Digite um celular válido' }));
        }
        return hasError;
    }

    useEffect(() => {
        let shortcutDelete;
        let shortcutConfirm;

        if (uuid) {
            if (canRemove) {
                shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
                    buttonDeleteRef.current?.click();
                });
            }
        }

        if (canSave) {
            shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutConfirm);
        };
    }, []);

    function back() {
        navigate(getRepresentativesRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();

        try {
            setSaveLoading(true);

            const body = {
                ...formData,
                email: formData.email.trim() ? formData.email : null,
                picture: pictureChanged ? formData.picture : null,
                suppliers: formData.suppliers.map((supplier) => ({
                    uuid: supplier.uuid,
                    minimumOrder: extractCurrencyNumber(
                        supplier.minimumOrder ?? ''
                    ),
                    quotationType: supplier.quotationType?.id,
                    responsible: supplier.responsible?.id ?? null,
                })),
            };

            let message;
            if (!uuid) { // new collaborator
                await RepresentativesService.addRepresentative(body);
                // I18N
                message = 'Representante cadastrado com sucesso!';
            } else {
                await RepresentativesService.editRepresentative(uuid, body);
                // I18N
                message = 'Representante editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create representative', e, null, title, ValidationErrorRepresentativeText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteRepresentative() {
        // I18N
        let title = 'Excluir representante';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await RepresentativesService.removeRepresentative(uuid);
                // I18N
                const message = 'Representante excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete representative', e, null, title, ValidationErrorRepresentativeText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o representante?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar representante';
            if (windowTitle && representativeName) title += ` - ${representativeName}`;
            return title;
        } else {
            // I18N
            return 'Novo representante';
        }
    }

    const renderForm = () => {
        if (loadingRepresentative) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados pessoais'}
                        </legend>
                        <div className={'gd'}>
                            <div className={'gd-col-2 gd-col--center gd-col--middle'}>
                                <Picture
                                    style={PictureStyle.USER_ROUNDED}
                                    size={PictureSize.LARGE}
                                    editPicture={canSave}
                                    picture={formData?.picture}
                                    onChange={(imageBase64) => {
                                        updateFormData({ picture: imageBase64 });
                                        setPictureChanged(true);
                                    }}
                                />
                            </div>
                            <div className={'gd-col-6'}>
                                <FieldText
                                    // I18N
                                    label={'Nome'}
                                    onChange={({ target }) => updateFormData({ name: target.value })}
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-4'}>
                                <FieldPhone
                                    ref={phoneInputRef}
                                    // I18N
                                    label={'Telefone'}
                                    onChange={({ target }) => updateFormData({ phone: target.value })}
                                    value={formData?.phone}
                                    validationError={formError?.phone}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldPhone
                                    ref={phoneInputRef}
                                    // I18N
                                    label={'Celular'}
                                    onChange={({ target }) => updateFormData({ cellphone: target.value })}
                                    value={formData?.cellphone}
                                    validationError={formError?.cellphone}
                                    readOnly={!canSave}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'E-mail'}
                                    onChange={({ target }) => updateFormData({ email: target.value })}
                                    value={formData?.email}
                                    validationError={formError?.email}
                                    maxLength={80}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                        <div className={'gd'}>
                            <div className={'gd-col-12'}>
                                <FieldTextarea
                                    // I18N
                                    label={'Observações gerais'}
                                    onChange={({ target }) => updateFormData({ observation: target.value })}
                                    value={formData?.observation}
                                    validationError={formError?.observation}
                                    maxLength={1024}
                                    rows={4}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Fornecedores'}
                        </legend>
                        <Suppliers
                            value={formData.suppliers}
                            onChange={(suppliers) => updateFormData({ suppliers })}
                            readOnly={!canSave}
                        />
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    style={{ display: `${uuid ? '' : 'none'}` }}
                                    loading={deleteLoading}
                                    onClick={deleteRepresentative}
                                >
                                    {`Excluir [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Representantes', route: getRepresentativesRoute() },
                        { name: uuid ? (loadingRepresentative ? '...' : representativeName) : title },
                    ]}
                    backRoute={getRepresentativesRoute()}
                    hideStore
                >
                </ScreenHeader>
                {renderForm()}
            </div>
        </>
    );
}

export function getRepresentativeFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/compras/representantes/${uuid}`;
    } else {
        return '/compras/representantes/novo';
    }
}
