import api from '../util/api';

export async function getProductsFamilies(page, search, order, cancelToken) {
    return (await api.get('/products-families', {
        params: {
            page,
            search,
            order
        },
        cancelToken: cancelToken
    })).data;
}

export async function getProductFamily(uuid) {
    return (await api.get(`/products-families/${uuid}`)).data;
}

export async function addProductFamily(formData) {
    return await api.post('/products-families', formData);
}

export async function editProductFamily({ uuid, formData }) {
    return await api.put(`/products-families/${uuid}`, formData);
}

export async function deleteProductFamily(uuid) {
    return await api.delete(`/products-families/${uuid}`);
}

export async function getProductsByFamily({ uuid, params }) {
    const data = (await api.get(`/products-families/${uuid}/products`, {
        params: {
            ...params
        }
    })).data;

    const result = {
        ...data,
        avgFamily: calculateAvgFamily({ data: data.records }),
    };

    return result;
}

function calculateAvgFamily({ data }) {

    if (data.length === 0) {
        return 0;
    }

    const { soma, somaQty } = data.reduce(
        (acc, produto) => {
            acc.soma += produto.minValue1 * produto.avgCost;
            acc.somaQty += produto.minValue1;
            return acc;
        },
        { soma: 0, somaQty: 0 }
    );

    if (somaQty === 0) return 0;

    return (soma / somaQty);
}