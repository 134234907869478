import { isValidElement, useContext, useEffect, useRef } from 'react';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import './InfoModal.scss';
import classNames from 'classnames';
import { ENUM_SHORTCUT } from 'erva-doce-common';

function SimpleConfirmModal({
    title,
    show,
    onConfirm,
    onCancel,
    cancelText,
    confirmText,
    children,
    zIndex,
    className,
    danger = false,
    disabled,
    footerColumn,
    text
}) {
    const lastShowRef = useRef(false);
    const cancelButtonRef = useRef(null);
    const confirmButtonRef = useRef(null);
    const {
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);

    useEffect(() => {
        // avoid unnecessary actions
        if (show === lastShowRef.current) return;
        lastShowRef.current = show;

        let shortcutCancel;
        let shortcutConfirm;

        function removeHotkeys() {
            removeHotkey(shortcutCancel);
            removeHotkey(shortcutConfirm);
        }

        if (show) {
            shortcutCancel = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => cancelButtonRef.current?.click());
            shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => confirmButtonRef.current?.click());

        } else {
            removeHotkeys();

        }
        return () => {
            removeHotkeys();

        };
    }, [show]);

    if (!isValidElement(title) && title) {
        title = (
            <h2>
                {title}
            </h2>
        );
    }

    return (
        <div
            style={{ zIndex: zIndex ?? 10010 }}
            className={`
            modal
            info-modal
            ${danger ? 'error' : 'success'}
            ${show ? 'show' : ''}
            ${className ?? ''}`
            }>
            <div className={'backdrop'} />
            <div className={'container'}>
                <div className={'body'}>
                    {
                        title && (
                            <div className={'title'}>
                                {title}
                                <div className={'text'}>
                                    {text}
                                </div>

                            </div>

                        )

                    }
                    {children}
                    <div className={'controls'}>
                        <div className={classNames('controls', { footerColumn })}>
                            {onCancel &&
                                <Button
                                    ref={cancelButtonRef}
                                    color={ButtonColor.BUTTON_COLOR_GRAY}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    onClick={onCancel}
                                >
                                    {/*I18N*/}
                                    {`${cancelText ?? 'Cancelar'} [${ENUM_SHORTCUT.SHORTCUT_CANCEL}]`}
                                </Button>
                            }

                            {onConfirm &&
                                <Button
                                    ref={confirmButtonRef}
                                    color={danger ? ButtonColor.BUTTON_COLOR_RED : ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    onClick={onConfirm}
                                    className={disabled && 'disabled'}
                                    disabled={disabled}
                                >
                                    {/*I18N*/}
                                    {`${confirmText ?? 'Confirmar'} [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SimpleConfirmModal;
