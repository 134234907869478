import './GeneralCashClosingScss.scss';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import FieldDate from '../../components/FieldDate';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getPanelPage } from './Panel';
import { getDateToDayForInputHtml } from '../../util/utilDate';
import Table from '../../components/Table';
import { formatValue } from '../../util/utilCurrency';
import Section from '../../components/Section';
import { useNavigate } from 'react-router';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getCashClosingPage } from './CashClosing';
import * as MovementService from '../../services/MovementService';
import classNames from 'classnames';
import { ENUM_SHORTCUT, CashierStatusEnum } from 'erva-doce-common';

export default function GeneralCashClosingPage() {
    const title = 'Painel Geral';
    const [data, setDate] = useState('');
    const [cashierInformation, setCashierInformation] = useState([]);
    const [totalValues, setTotalValues] = useState({});
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const navigate = useNavigate();
    const { addHotkey, removeHotkey, selectedStore } =
        useContext(EnvironmentContext);

    const formatedDate = ({ type }) => {
        if (data === '') {
            return type;
        }
        const [aaaa, mm, dd] = data.split('-');
        return `${dd}/${mm}/${aaaa} - ${type}`;
    };

    const calculateTotals = useCallback(() => {
        return cashierInformation?.reduce((acc, cashier) => {
            [
                'openningValue',
                'status',
                'sales',
                'reinforcement',
                'exchanges',
                'sangria',
                'creditCards',
                'money',
                'nf',
                'balanceDifference',
                'cardBalanceDifference'
            ].forEach((key) => (acc[key] = (acc[key] || 0) + cashier[key]));
            return acc;
        }, {});
    }, [cashierInformation]);

    const callsTheCashierDetailsPage = ({ uuid }) => {
        navigate(getCashClosingPage(uuid));
    };

    const getSummaryMovements = async () => {
        try {
            if (data) {
                const result = await MovementService.getSummaryMovement({    
                    date: data,
                });
                setCashierInformation(result);
            }
        } catch (error) {
            console.error('Erro ao trazer os dados de Summary Movements', error);
        }
    };

    const statusMap = {
        [CashierStatusEnum.OPEN]: 'Aberto',
        [CashierStatusEnum.CLOSED]: 'Fechado',
    };

    useEffect(() => setTotalValues(calculateTotals), [calculateTotals]);

    useEffect(() => {
        const shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () =>
            buttonDeleteRef.current?.click()
        );
        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => buttonSaveRef.current?.click());
        return () => {
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutConfirm);
        };
    }, [addHotkey, removeHotkey]);

    useEffect(() => {
        const formatedDate = getDateToDayForInputHtml();
        setDate(formatedDate);
    }, []);

    useEffect(() => {
        getSummaryMovements();
    }, [data, selectedStore]);

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Caixa', route: '/' },
                    { name: title, route: getPanelPage() },
                ]}
            />

            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-10'}></div>
                    <div className={'gd-col gd-col-2'}>
                        <FieldDate
                            onChange={({ target }) => setDate(target.value)}
                            value={data}
                        />
                    </div>
                </div>
            </div>
            <Section title={formatedDate({ type: 'Resumo Movimento' })}>
                <div className={'dash-table-container'}>
                    <Table
                        columns={[
                            { name: 'Caixa', align: 'center' },
                            { name: 'Colaborador', align: 'center' },
                            { name: 'Status atual', align: 'center' },
                            { name: 'Valor abertura', align: 'center' },
                            { name: 'Vendas', align: 'center' },
                            { name: 'Reforço', align: 'center' },
                            { name: 'Trocas', align: 'center' },
                            { name: 'Sangria', align: 'center' },
                            { name: 'Cartões', align: 'center' },
                            { name: 'Dinheiro', align: 'center' },
                            { name: 'NF', align: 'center' },
                            { name: 'Dif. Dinheiro', align: 'center' },
                            { name: 'Dif. Cartão', align: 'center' },
                        ]}
                    >
                        {cashierInformation?.map((cashier, index) => {
                            return (
                                <tr
                                    key={index}
                                    onClick={() => {
                                        callsTheCashierDetailsPage({
                                            uuid: cashier.uuid,
                                            date: data,
                                        });
                                    }}
                                >
                                    <td className={'center'}>
                                        <p
                                            className={classNames({
                                                'crossed-out-line': cashier.cashier.deleted,
                                            })}
                                        >{`${cashier.cashier}`}</p>
                                    </td>
                                    <td className={'center'}>
                                        {cashier.collaborator}
                                    </td>
                                    <td className={'center'}>
                                        {statusMap[cashier.status]}
                                    </td>
                                    <td className={'center'}>
                                        {formatValue({
                                            value: cashier.openningValue,
                                        })}
                                    </td>
                                    <td className={'center'}>
                                        {formatValue({ value: cashier.sales })}
                                    </td>
                                    <td className={'center'}>
                                        {formatValue({
                                            value: cashier.reinforcement,
                                        })}
                                    </td>
                                    <td className={'center'}>
                                        {formatValue({
                                            value: cashier.exchanges,
                                        })}
                                    </td>
                                    <td className={'center'}>
                                        {formatValue({
                                            value: cashier.sangria,
                                        })}
                                    </td>
                                    <td className={'center'}>
                                        {formatValue({
                                            value: cashier.creditCards,
                                        })}
                                    </td>
                                    <td className={'center'}>
                                        {formatValue({
                                            value: cashier.money,
                                        })}
                                    </td>
                                    <td className={'center'}>{cashier.nf}</td>
                                    <td className={'center'}>
                                        {formatValue({
                                            value: cashier.balanceDifference,
                                        })}
                                    </td>
                                    <td className={'center'}>
                                        {formatValue({
                                            value: cashier.cardBalanceDifference,
                                        })}
                                    </td>
                                </tr>
                            );
                        })}
                        <tr className={'adm-total-row'}>
                            <td className={'center'}>{'Total'}</td>
                            <td className={'center'}>
                                {totalValues?.collaborator}
                            </td>
                            <td className={'center'}>
                                {statusMap[totalValues?.status]}
                            </td>
                            <td className={'center'}>
                                {formatValue({
                                    value: totalValues?.openningValue,
                                })}
                            </td>
                            <td className={'center'}>
                                {formatValue({ value: totalValues?.sales })}
                            </td>
                            <td className={'center'}>
                                {formatValue({
                                    value: totalValues?.reinforcement,
                                })}
                            </td>
                            <td className={'center'}>
                                {formatValue({ value: totalValues?.exchanges })}
                            </td>
                            <td className={'center'}>
                                {formatValue({
                                    value: totalValues?.sangria,
                                })}
                            </td>
                            <td className={'center'}>
                                {formatValue({
                                    value: totalValues?.creditCards,
                                })}
                            </td>
                            <td className={'center'}>
                                {formatValue({
                                    value: totalValues?.money,
                                })}
                            </td>
                            <td className={'center'}>{totalValues?.nf}</td>
                            <td className={'center'}>
                                {formatValue({
                                    value: totalValues?.balanceDifference,
                                })}
                            </td>
                            <td className={'center'}>
                                {formatValue({
                                    value: totalValues?.cardBalanceDifference,
                                })}
                            </td>
                        </tr>

                    </Table>
                </div>
            </Section>
        </div>
    );
}

export function getGeneralCashClosingPage() {
    return '/vendas/dashboard';
}
