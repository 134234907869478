import * as Tabs from '@radix-ui/react-tabs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import Pagination from '../../components/Pagination';
import { IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import './PurchaseSuggestion.scss';
import SuggestionTable from './SuggestionTable';
import PurchaseSuggestionsFilterModal from './PurchaseSuggestionsFilterModal';
import { DateTime } from 'luxon';
import { differenceInDays, parseISO } from 'date-fns';
import * as PurchaseSuggestionService from '../../services/PurchaseSuggestionService';
import InputBox from '../../components/InputBox';
import { ENUM_SHORTCUT, OrderByPurchaseSuggestionEnum, PurchaseSuggestionFilterTabEnum, PurchaseSuggestionFilterTabText } from 'erva-doce-common';
import { EnvironmentContext } from '../../contexts/EnviromentContext';

const filters = {
    order: OrderByPurchaseSuggestionEnum.SUPPLIER_ASC,
    search: null,
    page: 0,
    ref1InitialDate: DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd'),
    ref1FinalDate: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
    ref2InitialDate: DateTime.now().minus({ days: 60 }).toFormat('yyyy-MM-dd'),
    ref2FinalDate: DateTime.now().minus({ days: 31 }).toFormat('yyyy-MM-dd'),
    suggestionType: PurchaseSuggestionFilterTabEnum.MINIMUM_REACHED,
    supplier: '',
    representative: '',
    daysSuggestion: 30,
};

function PurchaseSuggestion() {
    const inputSearchSupplierRef = useRef();
    const inputSearchRepresentativesRef = useRef();

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(filters);
    const [daysSuggestionForm, setDaysSuggestionForm] = useState(30);
    const [search, setSearch] = useState({
        representative: '',
        supplier: '',
    });
    const [data, setData] = useState([]);
    const { addHotkey, removeHotkey } = useContext(EnvironmentContext);
    const filterBtnRef = useRef();

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const fetchSuggestions = async () => {
        try {
            setIsLoading(true);
            const data = await PurchaseSuggestionService.getPurchaseSuggestions(
                filter
            );

            setData(data);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSuggestions();
    }, [filter]);

    useEffect(() => {

        const shortcutFilter = addHotkey(ENUM_SHORTCUT.SHORTCUT_FILTER, () => {
            if(filterBtnRef.current) {
                filterBtnRef.current.click();
            }
        });

        const shortCutSearchPrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY, () => {
            inputSearchSupplierRef.current?.focus();
        });

        const shortCutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRepresentativesRef.current?.focus();
        });

        return () => {
            removeHotkey(shortCutSearchPrimary);
            removeHotkey(shortCutSearchSecondary);
            removeHotkey(shortcutFilter);
        };

    }, []);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Sugestão de pedidos'}
                    breadcrumbs={[
                        { name: 'Compras', route: '/' },
                        {
                            name: 'Sugestão de pedidos',
                            route: getPurchaseSuggestionRoute(),
                        },
                    ]}
                    hideStore
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-3'}>
                        <FieldTextSearch
                            ref={inputSearchSupplierRef}
                            label={
                                `<em>Buscar por <strong>fornecedores [${ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY}]</strong></em>`
                            }
                            onChangeDebounce={() =>
                                updateFilter('supplier', search.supplier)
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    supplier: e.target.value,
                                })
                            }
                            value={search.supplier}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-3'}>
                        <FieldTextSearch
                            ref={inputSearchRepresentativesRef}
                            label={
                                `<em>Buscar por <strong>representantes [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</strong></em>`
                            }
                            onChangeDebounce={() =>
                                updateFilter(
                                    'representative',
                                    search.representative
                                )
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    representative: e.target.value,
                                })
                            }
                            value={search.representative}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-3'}>
                        <InputBox
                            value={daysSuggestionForm}
                            onChange={(e) =>
                                setDaysSuggestionForm(e.target.value)
                            }
                            onBlur={(e) =>
                                setFilter({
                                    ...filter,
                                    daysSuggestion: e.target.value,
                                })
                            }
                            label={'Sugestão em dias'}
                        />
                    </div>
                    <div className={'col-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            ref={filterBtnRef}
                            className={'w-100'}
                        >
                            {`Filtrar [${ENUM_SHORTCUT.SHORTCUT_FILTER}]`}
                        </Button>
                    </div>
                </div>
                <div className={'purchase_suggestion_list'}>
                    <Tabs.Root
                        defaultValue={filter.suggestionType}
                        className={'tabs_container'}
                        onValueChange={(e) => updateFilter('suggestionType', e)}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            {Object.values(PurchaseSuggestionFilterTabEnum).map(
                                (item, index) => {
                                    return (
                                        <Tabs.Trigger
                                            key={index}
                                            className={'tabs_trigger'}
                                            value={item}
                                        >
                                            {`${PurchaseSuggestionFilterTabText(item)} (${data.totals[item]?.count || 0})`}
                                        </Tabs.Trigger>
                                    );
                                })}
                        </Tabs.List>
                    </Tabs.Root>
                    <SuggestionTable
                        data={data?.records}
                        filter={filter}
                        onSortChange={updateFilter}
                        isLoading={isLoading}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
            </div>
            <PurchaseSuggestionsFilterModal
                show={showFilterModal}
                filter={filter}
                onCancel={() => setShowFilterModal(false)}
                onConfirm={(filterData) => {
                    setShowFilterModal(false);
                    setFilter({
                        ...filter,
                        ref1InitialDate: parseISO(filterData.ref1InitialDate),
                        ref2InitialDate: parseISO(filterData.ref2InitialDate),
                        ref1FinalDate: parseISO(filterData.ref1FinalDate),
                        ref2FinalDate: parseISO(filterData.ref2FinalDate),
                        ref1: differenceInDays(
                            parseISO(filterData.ref1FinalDate),
                            parseISO(filterData.ref1InitialDate)
                        ),
                        ref2: differenceInDays(
                            parseISO(filterData.ref2FinalDate),
                            parseISO(filterData.ref2InitialDate)
                        ),
                        suggestion: filterData.suggestion,
                    });
                }}
            />
        </>
    );
}

export default PurchaseSuggestion;

export function getPurchaseSuggestionRoute() {
    //I18N
    return '/compras/sugestao-de-pedido';
}
