import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    ENUM_SHORTCUT,
    OrderByTaxesSituationsEnum,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as TaxesSituationsService from '../../services/TaxesSituationsService';
import { getTaxSituationFormRoute } from './TaxSituationForm';

export default function TaxesSituations() {
    const [taxesSituations, setTaxesSituations] = useState(null);
    const [taxesSituationsLoading, setTaxesSituationsLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByTaxesSituationsEnum.NAME_ASC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_FISCAL);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchTaxesSituations();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getTaxSituationFormRoute());
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    async function fetchTaxesSituations() {
        if (taxesSituationsLoading?.cancel) {
            taxesSituationsLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setTaxesSituations(cancelToken);
            const response = await TaxesSituationsService.getTaxesSituations(filter.page, filter.search, filter.order,
                cancelToken);
            setTaxesSituations(response);
            setTaxesSituationsLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setTaxesSituationsLoading(e);
                setTaxesSituations(null);
            }
        }
    }

    function renderTable() {
        if (taxesSituationsLoading && taxesSituationsLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar as situações tributárias'}
                    onTryAgain={fetchTaxesSituations}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = taxesSituations?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getTaxSituationFormRoute(s.uuid))}>
                    <td>
                        {s.name}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={taxesSituationsLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nome da situação tributária',
                            sortAsc: OrderByTaxesSituationsEnum.NAME_ASC,
                            sortDesc: OrderByTaxesSituationsEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={taxesSituations?.page}
                    pageSize={taxesSituations?.pageSize}
                    count={taxesSituations?.count}
                    recordCount={taxesSituations?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                // I18N
                title={'Situações tributárias'}
                breadcrumbs={[
                    // I18N
                    { name: 'Fiscal', route: getDashboardRoute() },
                    // I18N
                    { name: 'Situações tributárias', route: getTaxesSituationsRoute() },
                ]}
                hideStore
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            // I18N
                            label={`<em>Buscar por <strong>situações tributárias</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                            onChange={({ target }) =>
                                setFilter({ ...filter, search: target.value })
                            }
                        />
                    </div>
                    {canAdd && (
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getTaxSituationFormRoute()}
                            >
                                {`Nova situação tributária [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className={'table-scroll'}>
                {renderTable()}
            </div>
        </div>
    );
}


export function getTaxesSituationsRoute() {
    //I18N
    return '/fiscal/situacoes-tributarias';
}
