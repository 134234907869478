import { useContext, useEffect, useState } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import './CashierOpeningModal.scss';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as CashiersService from '../../services/CashiersService';
import * as CashClosingService from '../../services/CashClosingService';


function CashierOpeningModal({
    show,
    onCancel,
    setShowCashierModal,
    setShowSaleModal

}) {

    const {
        user,
    } = useContext(EnvironmentContext);
    const [balance, setBalance] = useState(null);


    useEffect(() => {
        async function fetchBalance() {
            const collaboratorData = await CashiersService.getByUuidCollaborator(user.uuid);
            try {
                const balance = await CashClosingService.getCashClosingByCashier(collaboratorData.id);
                const balanceDay = balance?.balanceday?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                setBalance(balanceDay);
            } catch (error) {
                console.error('Failed to fetch balance', error);
            }
        }

        if (user) {
            fetchBalance();
        }
    }, [user]);


    async function confirm() {
        const collaboratorData = await CashiersService.getByUuidCollaborator(user.uuid);
        await CashiersService.openCashier(collaboratorData.uuid);
        setShowCashierModal(false);
        setShowSaleModal(true);
        window.location.reload();
    }

    function cancel() {
        onCancel();
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            confirmText={'Abrir Caixa'}
            onConfirm={confirm}
            // I18N
            title={'Abrir Caixa'}
            text={'Caixa fechado. Deseja abrir caixa?'}
        >
            <div className={'gd'}>
                <div className={'gd-col-12'}>
                    <h2 className={'Opening-balance'}>{'Saldo Inicial: '}{
                        balance ? balance : 'R$ 0,00'
                    } </h2>
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default CashierOpeningModal;
