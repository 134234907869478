import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ENUM_SHORTCUT, OrderByJobsTitlesEnum, PermissionsEnum } from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as JobsTitlesService from '../../services/JobsTitlesService';
import { getJobTitleFormRoute } from './JobTitleForm';

export default function JobsTitles() {
    const [jobsTitles, setJobsTitles] = useState(null);
    const [jobsTitlesLoading, setJobsTitlesLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByJobsTitlesEnum.NAME_ASC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_JOB_TITLES);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchJobsTitles();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchSecondary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getJobTitleFormRoute());
        });

        return () => {
            removeHotkey(shortcutSearchSecondary);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    async function fetchJobsTitles() {
        if (jobsTitlesLoading?.cancel) {
            jobsTitlesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setJobsTitlesLoading(cancelToken);
            const response = await JobsTitlesService.getJobsTitles(filter.page, filter.search, filter.order,
                cancelToken);
            setJobsTitles(response);
            setJobsTitlesLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setJobsTitlesLoading(e);
                setJobsTitles(null);
            }
        }
    }

    function renderTable() {
        if (jobsTitlesLoading && jobsTitlesLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os cargos'}
                    onTryAgain={fetchJobsTitles}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = jobsTitles?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getJobTitleFormRoute(s.uuid))}>
                    <td>
                        {s.name}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={jobsTitlesLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nome do cargo',
                            sortAsc: OrderByJobsTitlesEnum.NAME_ASC,
                            sortDesc: OrderByJobsTitlesEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={jobsTitles?.page}
                    pageSize={jobsTitles?.pageSize}
                    count={jobsTitles?.count}
                    recordCount={jobsTitles?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                // I18N
                title={'Cargos'}
                breadcrumbs={[
                    // I18N
                    { name: 'Administrativo', route: getDashboardRoute() },
                    // I18N
                    { name: 'Cargos', route: getJobsTitlesRoute() },
                ]}
                hideStore
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            // I18N
                            label={`<em>Buscar por <strong>cargos</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                            onChange={({ target }) =>
                                setFilter({ ...filter, search: target.value })
                            }
                        />
                    </div>
                    {canAdd && (
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getJobTitleFormRoute()}
                            >
                                {`Novo cargo [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className={'table-scroll'}>
                {renderTable()}
            </div>
        </div>
    );
}

export function getJobsTitlesRoute() {
    //I18N
    return '/administrativo/cargos';
}
