import FieldLiveSearch from './FieldLiveSearch';
import { useRef } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as PaymentsTypesService from '../services/PaymentsTypesService';
import { OrderByPaymentsTypesEnum } from 'erva-doce-common';
import { IconArrowDropdown } from './images';

function FieldPaymentType({
    label,
    onSelected,
    select,
    shouldFilterDinheiro = false,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(uuid) {
        const result = await PaymentsTypesService.getPaymentsTypes(0, uuid, OrderByPaymentsTypesEnum.NAME_ASC);
        return result['records']?.length ? result['records'][0].name : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await PaymentsTypesService.getPaymentsTypes(page, searchStr,
            OrderByPaymentsTypesEnum.NAME_ASC, cancelTokenSourceRef.current.token);

        let filteredRecords = result['records'];
        if (shouldFilterDinheiro) {
            filteredRecords = filteredRecords.filter(record => record['type'] !== 'DINNER');
        }
        return filteredRecords.map((record) => ({
            id: record['uuid'],
            value: record['name'],
            type: record['type'],
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            // I18N
            className={'field-payment-type'}
            label={label && label}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={true}
            icon={IconArrowDropdown}
            icon2x={IconArrowDropdown}
            {...props}
        />
    );
}

export default FieldPaymentType;