import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    ENUM_SHORTCUT,
    OrderByPaymentsTypesEnum,
    PermissionsEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as PaymentsTypesService from '../../services/PaymentsTypesService';
import { getPaymentTypeFormRoute } from './PaymentTypeForm';

export default function PaymentsTypes() {
    const [paymentsTypes, setPaymentsTypes] = useState(null);
    const [paymentsTypesLoading, setPaymentsTypesLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByPaymentsTypesEnum.NAME_ASC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchPaymentsTypes();
    }, [filter]);

    useEffect(() => {
        const shortcutSearchPrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_PRIMARY, () => {
            inputSearchRef.current?.focus();
        });

        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            navigate(getPaymentTypeFormRoute());
        });

        return () => {
            removeHotkey(shortcutSearchPrimary);
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    const canAdd = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_BILLS);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    async function fetchPaymentsTypes() {
        if (paymentsTypesLoading?.cancel) {
            paymentsTypesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setPaymentsTypesLoading(cancelToken);
            const response = await PaymentsTypesService.getPaymentsTypes(filter.page, filter.search, filter.order, cancelToken);
            setPaymentsTypes(response);
            setPaymentsTypesLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setPaymentsTypesLoading(e);
                setPaymentsTypes(null);
            }
        }
    }

    function renderTable() {
        if (paymentsTypesLoading && paymentsTypesLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os tipos de pagamentos'}
                    onTryAgain={fetchPaymentsTypes}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = paymentsTypes?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getPaymentTypeFormRoute(s.uuid))}>
                    <td>
                        {s.name}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={paymentsTypesLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nome do tipo de pagamento',
                            sortAsc: OrderByPaymentsTypesEnum.NAME_ASC,
                            sortDesc: OrderByPaymentsTypesEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={paymentsTypes?.page}
                    pageSize={paymentsTypes?.pageSize}
                    count={paymentsTypes?.count}
                    recordCount={paymentsTypes?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                // I18N
                title={'Tipos de pagamentos'}
                breadcrumbs={[
                    // I18N
                    { name: 'Financeiro', route: getDashboardRoute() },
                    // I18N
                    { name: 'Tipos de pagamentos', route: getPaymentsTypesRoute() },
                ]}
                hideStore
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={`<em>Buscar por <strong>nome</strong> [${ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY}]</em>`}
                            onChange={({ target }) =>
                                setFilter({ ...filter, search: target.value })
                            }
                        />
                    </div>
                    {canAdd && (
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getPaymentTypeFormRoute()}
                            >
                                {`Novo tipo de pagamento [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className={'table-scroll'}>
                {renderTable()}
            </div>
        </div>
    );
}

export function getPaymentsTypesRoute() {
    //I18N
    return '/financeiro/tipos-de-pagamentos';
}
