import React, { useContext, useEffect, useRef, useState } from 'react';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import FieldTextSearch from '../../components/FieldTextSearch';
import FieldCheckbox from '../../components/FieldCheckbox';
import './StockBulk.scss';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as StockService from '../../services/StockService';
import { getStockRoute } from './Stock';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { useNavigate } from 'react-router';
import FieldNumber from '../../components/FieldNumber';
import { IconArrowLeft, IconArrowLeftActive, IconArrowRight, IconArrowRightActive, IconCheckBox } from '../../components/images';
import { ENUM_SHORTCUT, OrderByStockEnum } from 'erva-doce-common';
import { InfoModalStyle } from '../../components/modal/InfoModal';

const filters = {
    search: null,
    order: null,
    page: 0,
    storeUrgent: false,
    cdUrgent: false,
};

export default function StockBulk() {
    const navigate = useNavigate();
    const title = 'Estoque';
    const { backendConnectionError, setConfirmModal, setInfoModal, selectedStore, addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const [filter, setFilter] = useState(filters);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [customProducts, setCustomProducts] = useState([]);
    const [selected, setSelected] = useState([]);
    const searchInputRef = useRef();
    const confirmBtnRef = useRef();
    const cancelBtnRef = useRef();

    const updateFilter = (name, value) => {
        setFilter(state => ({
            ...state,
            [name]: value,
        }));
    };

    const handleUpdateCustomProducts = (id, value) => {
        const newCustomProducts = [...customProducts];
        const index = newCustomProducts.findIndex(x => x.id === id);

        if (index > -1) {
            newCustomProducts[index] = {
                ...newCustomProducts[index],
                ...value,
            };
        } else {
            newCustomProducts.push({
                id,
                count: 0,
                to: null,
                from: null,
                ...value,
            });
        }

        setCustomProducts(newCustomProducts);
    };

    const updateCustomProducts = (records) => {
        const newCustomProducts = [...customProducts];

        records?.forEach(product => {
            const index = newCustomProducts.findIndex(x => x.id === product.id);

            if (index < 0 && product.count) {
                newCustomProducts.push({
                    id: product.id,
                    count: product.count,
                    to: product.to,
                    from: product.from,
                });
            }
        });

        setCustomProducts(newCustomProducts);
    };

    const handleDeleteProduct = (id) => {
        const newCustomProducts = [...customProducts].filter(x => x.id !== id);

        setCustomProducts(newCustomProducts);
    };

    const handleChangeDirection = (id, to, from) => {
        handleUpdateCustomProducts(id, { to, from, });
    };

    const handleSelect = (id) => {
        setSelected(
            selected.includes(id) ?
                selected.filter(x => x !== id) :
                [...selected, id]
        );
    };

    const handleSelectAll = (e) => {
        setSelected(e.target.checked ? data.records.map(x => x.id) : []);
    };

    const handleTransferProducts = async () => {
        if (!customProducts?.length) {
            setInfoModal({
                title: 'Transferir itens entre estoques',
                message: 'Edite os itens necessários antes de prosseguir',
                show: true,
            });
            return;
        }

        const proceed = async () => {
            try {
                setIsLoading(true);

                await StockService.tranferProduct(customProducts.filter(x => selected.includes(x.id)));

                setInfoModal({
                    title: 'Transferir itens entre estoques',
                    style: InfoModalStyle.SUCCESS,
                    message: 'Transferência realizada com sucesso',
                    onClose: () => navigate(getStockRoute()),
                    show: true,
                });

            } catch (err) {
                console.log(err);
                backendConnectionError('Fail to transfer Item', err, null, title);
            } finally {
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title: 'Transferir itens entre estoques',
            message: 'Você tem certeza de que deseja realizar a transferência?',
            onConfirm: proceed,
            show: true,
        });
    };

    const getItems = async () => {
        try {
            setIsLoading(true);

            const response = await StockService.getItems(filter);

            updateCustomProducts(response.records);
            setData(response);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch stock products', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getItems();
    }, [filter, selectedStore]);

    useEffect(() => {

        let shortcutSearch;
        let shortcutConfirm;
        let shortcutCancel;

        shortcutSearch = addHotkey(ENUM_SHORTCUT.SHORTCUT_SEARCH_SECONDARY, () => {
            if (searchInputRef.current) {
                searchInputRef.current?.focus();
            }
        });

        shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            if (confirmBtnRef.current) {
                confirmBtnRef.current?.click();
            }
        });

        shortcutCancel = addHotkey(ENUM_SHORTCUT.SHORTCUT_CANCEL, () => {
            if (cancelBtnRef.current) {
                cancelBtnRef.current?.click();
            }
        });

        return () => {
            removeHotkey(shortcutSearch);
            removeHotkey(shortcutConfirm);
            removeHotkey(shortcutCancel);
        };

    }, []);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={'Transferência em massa'}
                    backRoute={getStockRoute()}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Estoque' },
                        { name: 'Gerenciar', route: getStockRoute() },
                        { name: 'Transferência em massa', route: getStockBulkRoute() },
                    ]}

                />
                <div className={'row mb-32'}>
                    <div className={'col-4'}>
                        <FieldTextSearch
                            label={
                                '<em>Buscar por <strong>produto</strong> ou <strong>código</strong></em>'
                            }
                            onChangeDebounce={() =>
                                updateFilter('search', search)
                            }
                            onChange={(e) =>
                                setSearch(e.target.value)
                            }
                            value={search}
                            className={'text_filter'}
                            ref={searchInputRef}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-7 d-flex align-items-center'}>
                        <FieldCheckbox
                            className={'mb-0'}
                            type={'checkbox'}
                            inputs={[
                                {
                                    value: filter.cdUrgent,
                                    label: 'Loja >> CD',
                                    onChange: () => {
                                        updateFilter('cdUrgent', !filter.cdUrgent);
                                    },
                                    checked: filter.cdUrgent,
                                },
                                {
                                    value: filter.storeUrgent,
                                    label: 'CD >> Loja',
                                    onChange: () => {
                                        updateFilter('storeUrgent', !filter.storeUrgent);
                                    },
                                    checked: filter.storeUrgent,
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className={'mt-24'}>
                    <Table
                        loading={isLoading}
                        currentSort={filter.order}
                        selectAll={data?.records?.length === selected?.length}
                        handleSelectAll={handleSelectAll}
                        columns={[
                            {
                                name: 'EAN',
                                align: 'center',
                                width: '10%',
                                sortAsc: OrderByStockEnum.BARCODE_ASC,
                                sortDesc: OrderByStockEnum.BARCODE_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Produto',
                                width: '20%',
                                sortAsc: OrderByStockEnum.NAME_ASC,
                                sortDesc: OrderByStockEnum.NAME_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Estoque total',
                                align: 'center',
                            },
                            {
                                name: 'Estoque CD',
                                align: 'center',
                                className: 'border-left',
                                sortAsc: OrderByStockEnum.CD_STOCK_ASC,
                                sortDesc: OrderByStockEnum.CD_STOCK_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Venda mensal',
                                align: 'center',
                                sortAsc: OrderByStockEnum.MIN_VALUE_CD_ASC,
                                sortDesc: OrderByStockEnum.MIN_VALUE_CD_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Valor transf.',
                                align: 'center',
                                className: 'border-left border-right',
                            },
                            {
                                name: 'Estoque Loja',
                                align: 'center',
                                sortAsc: OrderByStockEnum.STORE_STOCK_ASC,
                                sortDesc: OrderByStockEnum.STORE_STOCK_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                name: 'Venda mensal',
                                align: 'center',
                                sortAsc: OrderByStockEnum.MIN_VALUE_STORE_ASC,
                                sortDesc: OrderByStockEnum.MIN_VALUE_STORE_DESC,
                                onSortChange: (order) => updateFilter('order', order),
                            },
                            {
                                width: '5%',
                            },
                            {
                                name: 'Ações',
                                align: 'end',
                                width: '5%',
                            },
                        ]}
                    >
                        {data?.records?.map((product) => {
                            const customProduct = customProducts.find(x => x.id === product.id);
                            return (
                                <tr key={product.id}>
                                    <td>
                                        <label className={'custom-checkbox'}>
                                            <input
                                                type={'checkbox'}
                                                checked={selected.includes(product.id)}
                                                onChange={() => handleSelect(product.id)}
                                                hidden
                                            />
                                            <span className={'custom-checkbox-icon'}>
                                                <img src={IconCheckBox} alt={'Check'} />
                                            </span>
                                        </label>
                                    </td>
                                    <td className={'text-center'}>{product.barCode}</td>
                                    <td>{product.name}</td>
                                    <td className={'text-center'}>{product.stock + product.cdStock}</td>
                                    <td className={'text-center border-left'}>{product.cdStock}</td>
                                    <td className={'text-center'}>{product.minValue1}</td>
                                    <td className={'text-center border-left border-right'}>
                                        <div className={'d-flex justify-content-center'}>
                                            <img src={customProduct?.to === 'CD' ? IconArrowLeftActive : IconArrowLeft} onClick={() => handleChangeDirection(product.id, 'CD', `${selectedStore.id}`)} />
                                            <div className={'count-selected ml-4 mr-4'}>
                                                <FieldNumber
                                                    fieldGroup={false}
                                                    required={true}
                                                    thousandsSeparator={false}
                                                    decimalLimit={0}
                                                    readOnly={!customProduct}
                                                    maxValue={customProduct?.from === 'CD' ? product.cdStock : product.stock}
                                                    value={customProduct?.count}
                                                    onChange={({ target }) => {
                                                        handleUpdateCustomProducts(product.id, { count: Number(target.value) });
                                                    }}
                                                />
                                            </div>
                                            <img src={customProduct?.to === `${selectedStore.id}` ? IconArrowRightActive : IconArrowRight} onClick={() => handleChangeDirection(product.id, `${selectedStore.id}`, 'CD')} />
                                        </div>
                                    </td>
                                    <td className={'text-center'}>{product.stock}</td>
                                    <td className={'text-center'}>{product.minValue1}</td>
                                    <td className={'text-center'}>{}</td>
                                    <td className={'text-center'}>
                                        <div className={'d-flex justify-content-end'}>
                                            <Button
                                                onClick={() => handleDeleteProduct(product.id)}
                                                className={'transparent'}
                                                style={{ textDecoration: 'underline' }}
                                            >
                                                {'Desfazer'}
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </Table>
                </div>
                <div>
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) =>
                            setFilter({ ...filter, page })
                        }
                    />
                </div>
                <div className={'row d-flex justify-content-end'}>
                    <div className={'col-6 align-right update-purchase'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                            className={'ml-10'}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            onClick={handleTransferProducts}
                            ref={confirmBtnRef}
                        >
                            {`Transferir [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                        </Button>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                            className={'ml-10'}
                            color={ButtonColor.BUTTON_COLOR_GRAY}
                            onClick={() => navigate(getStockRoute())}
                            ref={cancelBtnRef}
                        >
                            {`Cancelar [${ENUM_SHORTCUT.SHORTCUT_CANCEL}]`}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}


export function getStockBulkRoute() {
    return '/produtos/estoque/gerenciar/transferencia-em-massa';
}
