import { PaymentsEnum } from 'erva-doce-common';
import FieldCurrency, { extractCurrencyNumber, formatCurrency } from './FieldCurrency';
import FieldInteger from './FieldInteger';
import FieldMachine from './FieldMachine';
import FieldPaymentType from './FieldPaymentType';
import FieldText from './FieldText';



function PaymentList({ formData, updateFormData, removeRowPayment, currencyRefs, ...props }) {
    return (
        <div className={'payment-list'}>
            <div className={'gd field-group payment-header'}>
                <div className={'gd-col-2 mr-25'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Total'}</span>
                    </div>
                </div>
                <div className={'gd-col-2 mr-25'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Tipo'}</span>
                    </div>
                </div>
                <div className={'gd-col-3'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Máquina'}</span>
                    </div>
                </div>
                <div className={'gd-col-1 mr-25'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Parcelas'}</span>
                    </div>
                </div>
                <div className={'gd-col-3'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Valor parcelado'}</span>
                    </div>
                </div>
            </div>
            {formData.payments?.map((payment, i) => {
                let { total, paymentType, machine, quantityInstallments, amountInstallments, } = payment;
                return (
                    <div key={i} className={'gd field-group'}>
                        <div className={'remove'}>
                            {payment.tefId && payment.tefConfirmed ? <>{'OK'}</> : formData.payments.filter(x => !x.tefId && !x.tefConfirmed).length > 1 && (
                                <button
                                    className={'remove-button'}
                                    onClick={() => removeRowPayment(i)}
                                >
                                    {'X'}
                                </button>
                            )}
                        </div>
                        <div className={'gd-col-2 mr-25'}>
                            <FieldCurrency
                                value={total}
                                disabled={payment.tefId}
                                readOnly={payment.tefId}
                                className={'field-total'}
                                ref={(el) => currencyRefs.current[i] = el}
                                onChange={({ target }) => {
                                    payment.total = formatCurrency(target.value);
                                    const totalValue = (payment.total);
                                    payment.amountInstallments = quantityInstallments === 1
                                        ? payment.total
                                        : (totalValue / (quantityInstallments || 1)).toFixed(2);
                                    updateFormData({ payments: [...formData.payments] });

                                }}
                            />
                        </div>
                        <div className={'gd-col-2 mr-25'}>
                            <FieldPaymentType
                                // I18N
                                select={paymentType}
                                disabled={payment.tefId}
                                readOnly={payment.tefId}
                                onSelected={(pt) => {
                                    payment.paymentType = pt;

                                    if (pt?.type === PaymentsEnum.DINNER) {
                                        payment.machine = null;
                                    }
                                    updateFormData({ payments: [...formData.payments], });
                                }}
                            />
                        </div>
                        <div className={'gd-col-3'}>
                            {paymentType?.type !== PaymentsEnum.DINNER ? (
                                <FieldMachine
                                    // I18N
                                    select={machine}
                                    disabled={payment.tefId}
                                    readOnly={payment.tefId}
                                    onSelected={(m) => {
                                        payment.machine = m;
                                        updateFormData({ payments: [...formData.payments] });
                                    }}
                                />
                            ) : null}
                        </div>
                        <div className={'gd-col-1 mr-25'}>
                            <FieldInteger
                                // I18N
                                maxLength={2}
                                disabled={payment.tefId}
                                readOnly={payment.tefId}
                                value={quantityInstallments}
                                className={'field-quantity-installments'}
                                onChange={({ target }) => {
                                    const quantityInstallments = Number(target.value);
                                    const totalValue = extractCurrencyNumber(payment.total);
                                    payment.amountInstallments = quantityInstallments === 1
                                        ? payment.total
                                        : (totalValue / (quantityInstallments || 1)).toFixed(2);
                                    payment.quantityInstallments = quantityInstallments;
                                    updateFormData({ payments: [...formData.payments] });
                                }}
                            />

                        </div>
                        <div className={'gd-col-3'}>
                            <FieldText
                                maxLength={120}
                                readOnly={true}
                                validationError={false}
                                value={
                                    quantityInstallments === 1 ? total : formatCurrency(amountInstallments)
                                }
                                onChange={
                                    payment.amountInstallments = quantityInstallments === 1 ? total : formatCurrency(amountInstallments)
                                }
                                className={'field-amount-installments'}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default PaymentList;
