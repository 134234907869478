import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import { OrderByNameCashierEnum } from 'erva-doce-common';
import { formatValue } from '../../util/utilCurrency';
import * as CashMovementsService from '../../services/CashMovementsService';
import './CashFlowSummaryScss.scss';
import Button from '../../components/Button';
import TransactionModal from '../sales/TransactionModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import {
    IconButtonRemove,
    IconButtonRemove2x,
    IconButtonRemoveSvg,
} from '../../components/images';
import { PermissionsEnum } from 'erva-doce-common';
export default function CashFlowSummaryComponent({
    valueDate,
    cashClosinguuid,
    total,
    cashieruuid,
    cashierStatus,
}) {
    const { addHotkey, removeHotkey, user } = useContext(EnvironmentContext);
    const [showTransaction, setShowTransaction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [transactionTitle, setTransactionTitle] = useState('');
    const types = ['REINFORCEMENT', 'SANGRIA', 'EXCHANGES'];
    const [cashMovement, setCashMovement] = useState([]);
    const [filter, setFilter] = useState({
        order: OrderByNameCashierEnum.NAME_ASC,
        page: { REINFORCEMENT: 0, SANGRIA: 0, EXCHANGES: 0 },
        type: types,
        day: valueDate,
        cashieruuid: cashieruuid,
    });
    const realtype = {
        REINFORCEMENT: 'Reforço',
        SANGRIA: 'Sangria',
        EXCHANGES: 'Trocas',
    };


    const refs = {
        REINFORCEMENT: useRef(null),
        SANGRIA: useRef(null),
        EXCHANGES: useRef(null),
    };

    useEffect(() => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            day: valueDate,
            cashieruuid: cashieruuid,
            page: { REINFORCEMENT: 0, SANGRIA: 0, EXCHANGES: 0 },
        }));
        if (valueDate) {
            setCashMovement({
                REINFORCEMENT: { records: [], count: 0, total: 0 },
                SANGRIA: { records: [], count: 0, total: 0 },
                EXCHANGES: { records: [], count: 0, total: 0 },
            });
        }
    }, [valueDate]);
    useEffect(() => {
        fetchCashMovement();
    }, [filter]);

    const handleAddTransactionClick = (title) => {
        setTransactionTitle(title);
        setShowTransaction(true);
    };
    useEffect(() => {
        if (filter.day !== new Date().toISOString().split('T')[0] && user.permission !== PermissionsEnum.HANDLE_SALES) {
            return;
        }

        const f1Hotkey = addHotkey('F1', () =>
            handleAddTransactionClick('REINFORCEMENT')
        );
        const f2Hotkey = addHotkey('F2', () =>
            handleAddTransactionClick('SANGRIA')
        );

        return () => {
            removeHotkey(f1Hotkey);
            removeHotkey(f2Hotkey);
        };
    }, [addHotkey, removeHotkey, filter.day]);

    const handleRemoveItem = async (itemId) => {
        try {
            await CashMovementsService.deleteCashMovement(
                itemId, {
                    day: valueDate,
                    cashClosinguuid: cashClosinguuid,
                    total: total
                });
            window.location.reload();
        } catch (error) {
            console.error('Erro ao excluir o item:', error);
        }
    };

    const fetchCashMovement = useCallback(async () => {
        try {
            setIsLoading(true);
            const cancelToken = axios.CancelToken.source().token;
            const response = await CashMovementsService.getCahshMovements(
                filter.page,
                filter.search,
                filter.order,
                filter.type,
                filter.day,
                filter.cashieruuid,
                cancelToken
            );

            setCashMovement((prevCashMovement) => {
                const updatedCashMovement = { ...prevCashMovement };
                Object.keys(response).forEach((key) => {
                    if (updatedCashMovement[key]) {
                        const existingIds = new Set(
                            updatedCashMovement[key].records.map(
                                (record) => record.id
                            )
                        );
                        const newRecords = response[key].records.filter(
                            (record) => !existingIds.has(record.id)
                        );
                        updatedCashMovement[key].records = [
                            ...updatedCashMovement[key].records,
                            ...newRecords,
                        ];
                        updatedCashMovement[key].count = response[key].count;
                        updatedCashMovement[key].total = response[key].total;
                    } else {
                        updatedCashMovement[key] = response[key];
                    }
                });
                return updatedCashMovement;
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [filter]);

    const handleScroll = (type) => {
        if (isLoading) return;
        const listElement = refs[type].current;
        if (
            listElement.scrollTop + listElement.clientHeight >=
            listElement.scrollHeight - 10
        ) {
            loadMoreData(type);
        }
    };

    const loadMoreData = (type) => {
        const currentPage = filter.page[type] || 0;
        const currentRecords = cashMovement[type]?.records || [];
        const totalRecords = cashMovement[type]?.count || 0;
        if (currentRecords.length < totalRecords) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                page: {
                    ...prevFilter.page,
                    [type]: currentPage + 1,
                },
            }));
        }
    };

    useEffect(() => {
        types.forEach((type) => {
            const listElement = refs[type].current;
            if (listElement) {
                listElement.addEventListener('scroll', () =>
                    handleScroll(type)
                );
            }
        });

        return () => {
            types.forEach((type) => {
                const listElement = refs[type].current;
                if (listElement) {
                    listElement.removeEventListener('scroll', () =>
                        handleScroll(type)
                    );
                }
            });
        };
    }, [refs, handleScroll]);

    const cashMovements = {
        REINFORCEMENT: { records: [], count: 0, total: 0 },
        SANGRIA: { records: [], count: 0, total: 0 },
        EXCHANGES: { records: [], count: 0, total: 0 },
        ...cashMovement,
    };

    const info = [
        ...cashMovements.REINFORCEMENT.records,
        ...cashMovements.SANGRIA.records,
        ...cashMovements.EXCHANGES.records,
    ];

    return (
        <div className={'gd'}>
            {types.map((type, index) => (
                <div className={'info-table gd-col gd-col-4'} key={index}>
                    <div className={'title'}>
                        <p>
                            <strong>{realtype[type] || type}</strong>
                        </p>
                        {(['REINFORCEMENT', 'SANGRIA'].includes(type) && (
                            (cashierStatus === 'OPEN' && filter.day === new Date().toISOString().split('T')[0]) ||
                            user?.roles?.includes(PermissionsEnum.HANDLE_SALES)
                        )) && (
                            <p>
                                <Button
                                    className={'button-new-transaction'}
                                    onClick={() => handleAddTransactionClick(type)}
                                >
                                    {`+Adicionar F${index + 1}`}
                                </Button>
                            </p>
                        )}
                    </div>

                    <div className={'list'}>
                        <div className={'list-title'}>
                            <p>
                                <i>
                                    <strong>{`Itens ${cashMovements[type].count || 0
                                    }`}</strong>
                                </i>
                            </p>
                            <p>
                                <i>
                                    <strong>
                                        {`  ${cashMovements[
                                            type
                                        ].total.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        })}`}
                                    </strong>
                                </i>
                            </p>
                        </div>
                        <div className={'list-body-content'} ref={refs[type]}>
                            {info
                                .filter((i) => i.type === type)
                                .map((dataItem, dataIndex) => (
                                    <div
                                        className={'list-body'}
                                        key={dataIndex}
                                    >
                                        <div className={'list-body-title'}>
                                            <p>{dataItem.reason}</p>
                                        </div>
                                        <div className={'list-body-info'}>
                                            <span>{`Valor: ${formatValue({
                                                value: dataItem.value,
                                            })}`}</span>
                                            {(['REINFORCEMENT', 'SANGRIA'].includes(type) && (
                                                (cashierStatus === 'OPEN' && filter.day === new Date().toISOString().split('T')[0]) ||
                                                user?.roles?.includes(PermissionsEnum.HANDLE_SALES)
                                            )) && (
                                                <Button
                                                    className={
                                                        'transparent'
                                                    }
                                                    onClick={() =>
                                                        handleRemoveItem(
                                                            dataItem.uuid
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            IconButtonRemoveSvg
                                                        }
                                                        srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                                        alt={'Excluir'}
                                                        title={'Excluir'}
                                                        style={{
                                                            width: '14px',
                                                        }}
                                                    />
                                                </Button>
                                            )}

                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            ))}

            <TransactionModal
                show={showTransaction}
                title={transactionTitle}
                onCancel={() => {
                    setShowTransaction(false);
                }}
                valueDate={valueDate}
                cashClosinguuid={cashClosinguuid}
                total={total}
                cashieruuid={cashieruuid}
            />
        </div>
    );
}
