import React, { useContext, useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import { formatValue } from '../../util/utilCurrency';
import './CashClosingScss.scss';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';

import {
    IconButtonRemove,
    IconButtonRemove2x,
    IconButtonRemoveSvg,
} from '../../components/images';
import Section from '../../components/Section';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getPanelPage } from './Panel';
import { getGeneralCashClosingPage } from './GeneralCashClosing';
import FieldDate from '../../components/FieldDate';
import CashFlowSummaryComponent from './CashFlowSummary';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { useNavigate, useParams } from 'react-router';
import CalculateCash from './CalculateCash';
import * as CashClosingService from '../../services/CashClosingService';
import FieldPaymentType from '../../components/FieldPaymentType';
import FieldMachine from '../../components/FieldMachine';
import FieldCurrency, {
    extractCurrencyNumber,
    formatCurrency,
} from '../../components/FieldCurrency';
import { PermissionsEnum } from 'erva-doce-common';

export default function CashClosingPage() {
    const title = 'Fechamento de caixa';
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const [rows, setRows] = useState([
        { machine: '', operation: '', total: 0 },
    ]);
    const [infoCalculateCash, setInfoCalculateCash] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalMoney, setTotalMoney] = useState(0);
    const [valueDate, setValueDate] = useState('');
    const [finalBalance, setFinalBalance] = useState(0);
    const [finalBalanceType, setFinalBalanceType] = useState(0);
    const [cashClosinguuid, setCashClosinguuid] = useState('');
    const [cashierDate, setCashierDate] = useState('');
    const [status, setStatus] = useState('');
    const { addHotkey, removeHotkey, setConfirmModal, setLoading, user } =
        useContext(EnvironmentContext);
    const navigate = useNavigate();
    const { uuid } = useParams();

    function formatedDate({ type }) {
        if (valueDate === '') {
            return type;
        }
        const [aaaa, mm, dd] = valueDate.split('-');
        return `${dd}/${mm}/${aaaa} - ${type}`;
    }

    useEffect(() => {
        if (!valueDate) {
            setValueDate(new Date().toISOString().split('T')[0]);
        }
    }, [valueDate]);

    const addRow = () => {
        const newRow = { id: Date.now(), machine: '', operation: '', total: 0 };
        setRows([...rows, newRow]);
    };

    const removeRow = (id) => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const updateRow = (id, updates) => {
        setRows(
            rows.map((row) => (row.id === id ? { ...row, ...updates } : row))
        );
    };

    useEffect(() => {
        const sum = rows.reduce((acc, row) => acc + row.total, 0);
        setTotal(parseFloat(sum.toFixed(2)));
    }, [rows]);

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        let f4Hotkey;

        f4Hotkey = addHotkey('F4', () => {
            fetchEdit();
        });

        f3Hotkey = addHotkey('F3', () => {
            buttonDeleteRef.current?.click();
        });

        f6Hotkey = addHotkey('F6', () => {
            buttonSaveRef.current?.click();
        });
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
            removeHotkey(f4Hotkey);
        };
    }, []);

    const disabled = () => {
        if (status === 'OPEN') {
            return false;
        }

        if (
            user.roles?.includes(PermissionsEnum.HANDLE_SALES) &&
            status === 'CLOSED'
        ) {
            return false;
        }
        return true;
    };

    async function fetchDatelesscashclosing() {
        setLoading(true);
        try {
            const cashclosing = await CashClosingService.getDatelesscashclosing(
                uuid,
                valueDate
            );
            if (cashclosing) {
                const {
                    receiptBreakdown = [],
                    cashClosing,
                    cashBreakdown,
                    total,
                    totalCard,
                    date,
                    cashierStatus,
                } = cashclosing;

                const formattedRows = receiptBreakdown.length
                    ? receiptBreakdown.map(
                        ({
                            id,
                            cashClosingId,
                            machine,
                            operation,
                            total,
                        }) => ({
                            id,
                            cashClosingId,
                            machine: {
                                id: machine?.id,
                                value: machine?.name,
                            },
                            operation: {
                                id: operation?.id,
                                value: operation?.name,
                            },
                            total,
                        })
                    )
                    : [
                        {
                            id: Date.now(),
                            machine: '',
                            operation: '',
                            total: 0,
                        },
                    ];

                setRows(formattedRows);

                if (cashClosing) {
                    setCashClosinguuid(cashClosing[0]?.uuid);
                } else {
                    setCashClosinguuid('');
                }

                setStatus(cashierStatus);
                setInfoCalculateCash(cashBreakdown || []);
                setFinalBalance(total?.totalmoney || 0);
                setFinalBalanceType(
                    parseFloat(totalCard?.finalcardbalancesystem) || 0
                );
                setCashierDate(date);
            } else {
                setRows([
                    { id: Date.now(), machine: '', operation: '', total: 0 },
                ]);
                setFinalBalanceType(null);
                setInfoCalculateCash([]);
                setFinalBalance(0);
            }
        } catch (error) {
            console.error('Erro ao buscar o fechamento de caixa:', error);
        } finally {
            setLoading(false);
        }
    }

    async function fetchEdit() {
        setLoading(true);
        try {
            await CashClosingService.editClosingCashierJvvc(
                cashClosinguuid,
                infoCalculateCash,
                rows,
                totalMoney,
                total,
                valueDate
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    async function closingCashier() {
        setLoading(true);
        try {
            await CashClosingService.closingCashier(
                infoCalculateCash,
                rows,
                totalMoney,
                uuid,
                total
            );
            if (user.roles?.includes(PermissionsEnum.HANDLE_SALES)) {
                navigate(getGeneralCashClosingPage());
            } else {
                navigate(getPanelPage());
            }
            window.location.reload();
        } catch (error) {
            console.error('Error proceed', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchDatelesscashclosing();
    }, [valueDate, uuid]);

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={title}
                breadcrumbs={[
                    { name: 'Caixa', route: '/' },
                    { name: title, route: getPanelPage() },
                ]}
            />

            <div>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-10'}></div>
                    <div className={'gd-col gd-col-2'}>
                        <FieldDate
                            minDate={cashierDate}
                            onChange={({ target }) =>
                                setValueDate(target.value)
                            }
                            value={valueDate}
                        />
                    </div>
                </div>
            </div>

            <Section
                title={formatedDate({ type: 'Reforço, Sangria e Trocas' })}
            >
                <CashFlowSummaryComponent
                    valueDate={valueDate}
                    cashClosinguuid={cashClosinguuid}
                    total={totalMoney}
                    cashieruuid={uuid}
                    cashierStatus={status}
                />
            </Section>

            <Section title={formatedDate({ type: 'Conferência de Canhotos' })}>
                <div className={'box-table'}>
                    <div className={'facade'}>
                        <div>
                            <p>
                                <strong>{`Itens: ${rows?.length}`}</strong>
                            </p>
                        </div>
                        <div>
                            <p>
                                <i>
                                    <strong>
                                        {`Total geral de cartões ${formatValue({
                                            value: total,
                                        })} `}{' '}
                                    </strong>
                                </i>
                            </p>
                        </div>
                    </div>
                    <div className={'table-content'}>
                        <Table
                            columns={[
                                { name: 'Operadora', align: 'center' },
                                { name: 'Tipo operação', align: 'center' },
                                { name: 'Valor total', align: 'center' },
                                { name: '', align: 'center' },
                                { name: 'Excluir linha', align: 'center' },
                            ]}
                        >
                            {rows?.map((row) => {
                                return (
                                    <tr key={row?.id || 0}>
                                        <td className={'center'}>
                                            <FieldMachine
                                                disabled={disabled()}
                                                select={row?.machine}
                                                onSelected={(
                                                    selectedMachine
                                                ) => {
                                                    if (selectedMachine) {
                                                        updateRow(row.id, {
                                                            machine: {
                                                                id: selectedMachine.id,
                                                                value: selectedMachine.value,
                                                            },
                                                        });
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className={'center'}>
                                            <FieldPaymentType
                                                disabled={disabled()}
                                                shouldFilterDinheiro={true}
                                                select={row?.operation}
                                                onSelected={(
                                                    selectedOperation
                                                ) => {
                                                    if (selectedOperation) {
                                                        updateRow(row.id, {
                                                            operation: {
                                                                id: selectedOperation.id,
                                                                value: selectedOperation.value,
                                                            },
                                                        });
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className={'center'}>
                                            <FieldCurrency
                                                disabled={disabled()}
                                                value={
                                                    row?.total.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        }
                                                    ) || 'R$ 0,00'
                                                }
                                                onChange={({ target }) => {
                                                    const updatedRows =
                                                        rows.map((r) => {
                                                            if (
                                                                r.id === row.id
                                                            ) {
                                                                return {
                                                                    ...r,
                                                                    total: extractCurrencyNumber(
                                                                        formatCurrency(
                                                                            target.value
                                                                        )
                                                                    ),
                                                                };
                                                            }
                                                            return r;
                                                        });
                                                    setRows(updatedRows);
                                                }}
                                            />
                                        </td>
                                        <td></td>
                                        <td className={'center'}>
                                            <Button
                                                disabled={disabled()}
                                                className={'transparent'}
                                                onClick={() =>
                                                    removeRow(row.id)
                                                }
                                            >
                                                <img
                                                    src={IconButtonRemoveSvg}
                                                    srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                                    alt={'Excluir'}
                                                    title={'Excluir'}
                                                    style={{ width: '14px' }}
                                                />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </Table>
                        <div className={'box-button'}>
                            <Button
                                disabled={disabled()}
                                color={ButtonColor.BUTTON_COLOR_WHITE}
                                className={'button-new-machine'}
                                onClick={addRow}
                            >
                                {'+ Adicionar maquina'}
                            </Button>
                        </div>
                    </div>
                </div>
            </Section>

            <CalculateCash
                onTotalChange={setTotalMoney}
                setInfoCalculateCash={setInfoCalculateCash}
                valueDate={valueDate}
                infoCalculateCash={infoCalculateCash}
                disabled={disabled()}
            />

            <div className={'controls mt-42'}>
                {user?.roles?.includes(PermissionsEnum.HANDLE_SALES) && (
                    <>
                        <p className={'differencein-value'}>
                            {'Diferença no valor de: '}
                            <strong>
                                {formatValue({
                                    value: finalBalance - totalMoney,
                                })}
                            </strong>
                        </p>
                        <p className={'differencein-value'}>
                            {'Diferença no valor de Canhotos: '}
                            <strong>
                                {formatValue({
                                    value: finalBalanceType - total,
                                })}
                            </strong>
                        </p>
                    </>
                )}

                <div className={'row  buttons-controll'}>
                    <div className={'col-2'}>
                        <Button
                            className={'w-100'}
                            ref={buttonDeleteRef}
                            color={ButtonColor.BUTTON_COLOR_GRAY}
                            onClick={() => {
                                navigate(getPanelPage());
                            }}
                        >
                            {'Sair [F3]'}
                        </Button>
                    </div>

                    {user?.roles?.includes(PermissionsEnum.HANDLE_SALES) && (
                        <div className={'col-2'}>
                            <Button
                                disabled={!cashClosinguuid}
                                className={'w-100'}
                                ref={buttonDeleteRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={
                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                }
                                onClick={() => {
                                    navigate(fetchEdit());
                                }}
                            >
                                {'Salvar [F4]'}
                            </Button>
                        </div>
                    )}

                    <div className={'col-2'}>
                        <Button
                            className={'w-100'}
                            disabled={
                                status === 'CLOSED' ||
                                valueDate !==
                                    new Date().toISOString().split('T')[0]
                            }
                            ref={buttonSaveRef}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                            onClick={() => {
                                let message;
                                let cancelText;
                                let confirmText;
                                let onConfirm;
                                let onCancel;

                                if (
                                    finalBalance - totalMoney === 0 &&
                                    finalBalanceType - total === 0
                                ) {
                                    message =
                                        'Valores em dinheiro e canhotos lançados corretamente. Fechar caixa?';
                                    cancelText = 'Não';
                                    confirmText = 'Sim';
                                    onConfirm = () => closingCashier();
                                } else {
                                    message =
                                        'Verificamos uma quebra de caixa. Deseja calcular novamente?';
                                    cancelText = 'Não';
                                    confirmText = 'Sim';
                                    onCancel = () => closingCashier();
                                }

                                setConfirmModal({
                                    title: 'Fechamento de caixa',
                                    message: message,
                                    show: true,
                                    cancelText: cancelText,
                                    confirmText: confirmText,
                                    onConfirm: onConfirm,
                                    onCancel: onCancel,
                                });
                            }}
                        >
                            {'Fechar Caixa [F6]'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function getCashClosingPage(uuid) {
    if (uuid) {
        return `/vendas/fechamento/${uuid}`;
    }
    return '/vendas/fechamento';
}
