import { useParams } from 'react-router';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import { getSuppliersRoute } from '../suppliers/Suppliers';
import FieldTextSearch from '../../components/FieldTextSearch';
import Button, {
    ButtonColor,
    ButtonFontColor,
    ButtonStyle,
} from '../../components/Button';
import {
    IconFilter,
    IconFilter2x,
    IconWhatsapp,
} from '../../components/images';
import { useContext, useEffect, useState } from 'react';
import SearchProductModal from '../../components/SearchProductModal';
import * as SupplierRepresentativeService from '../../services/SupplierRepresentativeService';
import { PermissionsEnum } from 'erva-doce-common';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import FilterModal from './FilterModal';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { formatValue } from '../../util/formatValue';
import SupplierRepresentativeHeader from './SupplierRepresentativeHeader';
import Picture, { PictureSize, PictureStyle } from '../../components/Picture';
import { getSupplierFormRoute } from '../suppliers/SupplierForm';
import ImportFileModal from '../../components/ImportFileModal';

const INITIAL_FILTER = {
    page: 0,
    product: '',
};

const DEFAULT_FILENAME = {
    DEFAULT: 'PLANILHA_DE_CUSTOS',
};

export default function SupplierRepresentativeProducts() {
    const { id } = useParams();
    const { user, backendConnectionError } = useContext(EnvironmentContext);

    const canAddProduct =
        user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_SUPPLIERS);
    const [isLoading, setIsLoading] = useState(false);
    const [showImportFileModal, setShowImportFileModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [data, setData] = useState({});
    const [representativeSupplier, setRepresentativeSupplier] = useState({});

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const getItems = async () => {
        try {
            setIsLoading(true);
            const data = await SupplierRepresentativeService.getProducts(
                id,
                filter
            );
            setData(data);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    const getInfo = async () => {
        try {
            setIsLoading(true);
            const data = await SupplierRepresentativeService.getById(
                id,
                filter
            );
            setRepresentativeSupplier(data);
        } catch (err) {
            console.log(err);
            // SHOW MODAL ERROR
        } finally {
            setIsLoading(false);
        }
    };

    const addProduct = async (prod) => {
        try {
            setIsLoading(true);
            await SupplierRepresentativeService.addProduct(id, prod.uuid);
            getItems();
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const download = async () => {
        try {
            setIsLoading(true);
            const fileName = generateFileNameFromRepAndSupplier();
            await SupplierRepresentativeService.download({ id, fileName });
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const generateFileNameFromRepAndSupplier = () => {

        if (!representativeSupplier) {
            return DEFAULT_FILENAME.DEFAULT;
        }

        const { representativeName, supplierName } = representativeSupplier;

        const safeSupplierName = supplierName.replace(/\s+/g, '_').replace(/[^\w\s]/g, '');
        const safeRepresentativeName = representativeName.replace(/\s+/g, '_').replace(/[^\w\s]/g, '').toUpperCase();

        return `${safeSupplierName}_${safeRepresentativeName}`;
    };

    useEffect(() => {
        getItems();
    }, [filter]);

    useEffect(() => {
        getInfo();
    }, [id]);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Listagem de produtos'}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        { name: 'Fornecedores', route: getSuppliersRoute() },
                        {
                            name: 'Listagem de produtos',
                            route: getSupplierRepresentativeProductsRoute(id),
                        },
                    ]}
                    backRoute={getSupplierFormRoute(
                        representativeSupplier.supplierUUID
                    )}
                    hideStore
                />
                <SupplierRepresentativeHeader>
                    <tr>
                        <td>
                            <div className={'d-flex align-items-center'}>
                                <Picture
                                    picture={
                                        representativeSupplier?.supplierPicture
                                    }
                                    name={representativeSupplier.supplierName}
                                    size={PictureSize.MAIN_MENU}
                                    style={PictureStyle.USER_SQUARE}
                                    editPicture={false}
                                />
                                <p className={'ml-8'}>
                                    {representativeSupplier.supplierName}
                                </p>
                            </div>
                        </td>
                        <td>
                            <div className={'d-flex align-items-center'}>
                                <Picture
                                    picture={
                                        representativeSupplier?.representativePicture
                                    }
                                    name={
                                        representativeSupplier.representativeName
                                    }
                                    size={PictureSize.MAIN_MENU}
                                    style={PictureStyle.USER_SQUARE}
                                    editPicture={false}
                                />
                                <p className={'ml-8'}>
                                    {representativeSupplier.representativeName}
                                </p>
                            </div>
                        </td>
                        <td>
                            <img
                                src={IconWhatsapp}
                                alt={'Imagem WhatsApp'}
                                className={'mr-8'}
                            />
                            {representativeSupplier.representativePhone ||
                                'não informado'}
                        </td>
                        <td>
                            {formatValue(
                                representativeSupplier.minimumOrder || 0
                            )}
                        </td>
                    </tr>
                </SupplierRepresentativeHeader>
                <div className={'row row justify-content-end'}>
                    <div className={'col-4 d-flex'}>
                        <FieldTextSearch
                            label={
                                '<em>Buscar por <strong>produto</strong> [ F2 ]</em>'
                            }
                            onChangeDebounce={() =>
                                setFilter({
                                    ...filter,
                                    product: search,
                                })
                            }
                            onChange={(e) => setSearch(e.target.value)}
                            value={search.product}
                            className={'text_filter w-100'}
                        />
                        {canAddProduct && (
                            <Button
                                style={{ width: '60px' }}
                                className={'ml-8'}
                                title={'Adicionar produto'}
                                buttonStyle={ButtonStyle.BUTTON_TINY}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                onClick={() => {
                                    setShowProductModal(true);
                                }}
                            >
                                {'+'}
                            </Button>
                        )}
                    </div>
                    <div className={'col-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            className={'w-100'}
                        >
                            {'Filtrar'}
                        </Button>
                    </div>
                </div>
                <div className={'mt-20'}>
                    <Table
                        loading={isLoading}
                        className={'purchase-detail-list'}
                        columns={[
                            {
                                name: 'EAN',
                            },
                            {
                                name: 'Produtos',
                                width: '30%',
                            },
                            {
                                name: 'Custo',
                                align: 'center',
                            },
                            {
                                name: 'Custo Médio',
                                align: 'center',
                            },
                            {
                                name: 'Cód. fornecedor',
                                align: 'center',
                            },
                            {
                                name: 'Observações',
                                align: 'center',
                            },
                            {
                                name: 'Embalagem mínima',
                                align: 'center',
                            },
                            {
                                name: 'Data Reajuste',
                                align: 'center',
                            },
                        ]}
                    >
                        {data.records?.map((product, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {product.barCodes?.find((x) => x.main)
                                            ?.barCode || '-'}
                                    </td>
                                    <td>{product.name}</td>
                                    <td className={'text-center'}>
                                        {formatValue(product.cost)}
                                    </td>
                                    <td className={'text-center'}>
                                        {formatValue(product.avgCost)}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.supplierCode || '-'}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.observations || '-'}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.minPackageQuantity || '-'}
                                    </td>
                                    <td className={'text-center'}>
                                        {product.lastUpdate}
                                    </td>
                                </tr>
                            );
                        })}
                    </Table>
                    <div>
                        <Pagination
                            page={data?.page}
                            pageSize={data?.pageSize}
                            count={data?.count}
                            recordCount={data?.records?.length || 0}
                            onPageChange={(page) =>
                                setFilter({ ...filter, page })
                            }
                        />
                    </div>
                    <div className={'mt-20 d-flex justify-content-end row'}>
                        <div className={'col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={
                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                }
                                onClick={() => setShowImportFileModal(true)}
                                className={'w-100'}
                            >
                                {'Importar Planilha'}
                            </Button>
                        </div>
                        <div className={'col-2'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={
                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                }
                                onClick={download}
                                className={'w-100'}
                            >
                                {'Exportar Planilha'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <FilterModal
                show={showFilterModal}
                onCancel={() => setShowFilterModal(false)}
                onSelect={(filter) => {
                    setFilter((state) => ({ ...state, ...filter }));
                    setShowFilterModal(false);
                }}
            />
            <SearchProductModal
                show={showProductModal}
                onCancel={() => setShowProductModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        addProduct(prod);
                        setShowProductModal(false);
                    }
                }}
            />
            <ImportFileModal
                title={'Selecionar planilha xlsx'}
                accept={['xlsx']}
                show={showImportFileModal}
                onConfirm={() => {
                    setShowImportFileModal(false);
                    window.location.reload();
                }}
                onCancel={() => {
                    setShowImportFileModal(false);
                }}
                onRequestUpload={async (files, callback) => {
                    return await SupplierRepresentativeService.updateProduct({
                        data: files,
                        callback: callback,
                        representativeUuid: representativeSupplier.representativeUUID,
                        supplierUuid: representativeSupplier.supplierUUID,
                    });
                }}
            />
        </>
    );
}

export function getSupplierRepresentativeProductsRoute(id) {
    return `/compras/fornecedores-representantes/${id}/produtos`;
}
