import {  useRef } from 'react';
import './ProductTable.scss';

export default function Table({
    columns,
    children,
}) {
    const tbodyRef = useRef();
    const empty = !children;

    return (
        <div className={'product-table-container'}>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>{'Código'}</th>
                        <th>{'Nome do Produto'}</th>
                        <th>{'Variação'}</th>
                        <th>{'Mínimo CD / Loja'}</th>
                        <th>{'Saldo CD / Loja'}</th>
                        <th>{'Preço Base / Loja'}</th>
                        <th>{'Custo médio'}</th>
                        <th>{'Último custo'}</th>
                        <th>{'Margem atual'}</th>
                        <th>{'Queridinho?'}</th>
                        <th>{'Log'}</th>
                    </tr>
                </thead>
                <tbody ref={tbodyRef}>
                    {empty && (
                        <tr>
                            <td colSpan={columns?.length} className={'status-container'}>
                                {'Não há dados para exibir'}
                            </td>
                        </tr>
                    )}
                    {!empty && (
                        children
                    )}
                </tbody>
            </table>
        </div>
    );
}
