import { useNavigate, useParams } from 'react-router';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getAccountsRoute } from './Accounts';
import FieldText from '../../components/FieldText';
import { useContext, useEffect, useRef, useState } from 'react';
import * as AccountsService from '../../services/AccountsService';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { AccountBankEnum, AccountBankText, AccountTypeEnum, AccountTypeText, ENUM_SHORTCUT, isValidCnpj, isValidCpf } from 'erva-doce-common';
import FieldSelect from '../../components/FieldSelect';
import FieldCnpj from '../../components/FieldCnpj';
import FieldCpf from '../../components/FieldCpf';

const INITIAL_STATE = {
    bank: '',
    accountType: '',
    agency: '',
    accountNumber: '',
    document: '',
};

export default function AccountsForm() {
    const title = 'Contas bancárias';
    const navigate = useNavigate();
    const { uuid } = useParams();
    const isNew = !uuid;
    const { setInfoModal, backendConnectionError, addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [formError, setFormError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const saveBtnRef = useRef();
    const destroyBtnRef = useRef();

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationErrors = () => {
        let hasError = false;
        setFormError({});

        if (!formData.bank) {
            hasError = true;
            setFormError(state => ({ ...state, bank: 'Preencha o campo corretamente' }));
        }

        if (!formData.accountType) {
            hasError = true;
            setFormError(state => ({ ...state, accountType: 'Preencha o campo corretamente' }));
        } else {
            if (!formData.document) {
                hasError = true;
                setFormError(state => ({ ...state, document: 'Preencha o campo corretamente' }));
            } else {
                if (formData.accountType === AccountTypeEnum.PF && !isValidCpf(formData.document)) {
                    hasError = true;
                    setFormError(state => ({ ...state, document: 'Documento inválido' }));
                }
                if (formData.accountType === AccountTypeEnum.PJ && !isValidCnpj(formData.document)) {
                    hasError = true;
                    setFormError(state => ({ ...state, document: 'Documento inválido' }));
                }
            }
        }

        if (!formData.agency) {
            hasError = true;
            setFormError(state => ({ ...state, agency: 'Preencha o campo corretamente' }));
        }

        if (!formData.accountNumber) {
            hasError = true;
            setFormError(state => ({ ...state, accountNumber: 'Preencha o campo corretamente' }));
        }

        return hasError;
    };

    const save = async () => {
        if (hasValidationErrors()) return;

        try {
            setIsLoading(true);

            let message = '';

            if (isNew) {
                await AccountsService.create({
                    ...formData,
                });
                message = 'Nova conta criada com sucesso!';
            } else {
                await AccountsService.update(uuid, {
                    ...formData,
                });
                message = 'Conta atualizada com sucesso!';
            }

            setInfoModal({
                title,
                message,
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getAccountsRoute()),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save account', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const destroy = async () => {
        try {
            setIsLoading(true);
            await AccountsService.destroy(uuid);

            setInfoModal({
                title,
                message: 'Conta excluída com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getAccountsRoute()),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save account', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchInfo = async () => {
        try {
            const res = await AccountsService.getByUUID(uuid);

            setFormData({
                ...res,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleTypeOptions = () => {
        const options = [];
        for (const option in AccountTypeEnum) {
            options.push({
                id: option,
                value: AccountTypeText(option),
            });
        }

        return options;
    };

    const handleBankOptions = () => {
        const options = [];
        for (const option in AccountBankEnum) {
            options.push({
                id: option,
                value: AccountBankText(option),
            });
        }

        return options;
    };

    useEffect(() => {
        if (uuid) {
            fetchInfo();
        }
    }, [uuid]);

    useEffect(() => {

        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            if(saveBtnRef.current) {
                saveBtnRef.current.click();
            }
        });

        const shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
            if(destroyBtnRef.current) {
                destroyBtnRef.current.click();
            }
        });


        return () => {
            removeHotkey(shortcutConfirm);
            removeHotkey(shortcutDelete);
        };
    });

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '/' },
                        { name: title, route: getAccountsRoute() },
                        { name: uuid || 'Novo', route: getAccountsFormRoute(uuid) },
                    ]}
                    backRoute={getAccountsRoute()}
                    hideStore
                />
                <div className={'row'}>
                    <div className={'col-2'}>
                        <FieldSelect
                            label={'Banco'}
                            options={handleBankOptions()}
                            onChange={({ target }) => {
                                let bank = target.value;

                                if (target === '-1') {
                                    bank = null;
                                }

                                updateFormData({ bank });
                            }}
                            value={formData?.bank || '-1'}
                            validationError={formError?.bank}
                            placeholder={'Selecione'}
                            disableDefaultOption={false}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldText
                            label={'Agência'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ agency: target.value })}
                            value={formData?.agency}
                            validationError={formError?.agency}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldText
                            label={'Conta'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ accountNumber: target.value })}
                            value={formData?.accountNumber}
                            validationError={formError?.accountNumber}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldSelect
                            label={'Tipo de conta'}
                            options={handleTypeOptions()}
                            onChange={({ target }) => {
                                let accountType = target.value;

                                if (target === '-1') {
                                    accountType = null;
                                }

                                updateFormData({ accountType, document: null });
                            }}
                            value={formData?.accountType || '-1'}
                            validationError={formError?.accountType}
                            placeholder={'Selecione'}
                            disableDefaultOption={false}
                        />
                    </div>
                    <div className={'col-2'}>
                        {
                            formData.accountType && (
                                <>
                                    {
                                        formData.accountType === AccountTypeEnum.PF ? (
                                            <FieldCpf
                                                label={'CPF'}
                                                onChange={({ target }) => updateFormData({ document: target.value })}
                                                value={formData?.document}
                                                validationError={formError?.document}
                                            />
                                        ) : (
                                            <FieldCnpj
                                                label={'CNPJ'}
                                                onChange={({ target }) => updateFormData({ document: target.value })}
                                                value={formData?.document}
                                                validationError={formError?.document}
                                            />
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
                <div className={'row d-flex justify-content-end'}>
                    <div className={'col-6 align-right update-purchase'}>
                        {
                            !isNew && (
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10 w-25'}
                                    color={ButtonColor.BUTTON_COLOR_GRAY}
                                    onClick={destroy}
                                    ref={destroyBtnRef}
                                >
                                    {'Excluir'}
                                </Button>
                            )
                        }
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_NORMAL}
                            className={'ml-10 w-25'}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            onClick={save}
                            ref={saveBtnRef}
                        >
                            {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export function getAccountsFormRoute(uuid) {
    if (uuid) {
        return `/financeiro/contas-bancarias/${uuid}`;
    }

    return '/financeiro/contas-bancarias/novo';
}
