import { useState, useEffect } from 'react';
import Table from '../../components/Table';
import FieldNumber from '../../components/FieldNumber';
import { formatValue } from '../../util/utilCurrency';
import Section from '../../components/Section';
import ButtonRemove from '../../components/ButtonRemove';

const TOTALS_TABLE = [
    { coin: 'fiveCents', total: 0, pct: 20, value: 0.05, package: '', loose: '' },
    { coin: 'tenCents', total: 0, pct: 10, value: 0.1, package: '', loose: '' },
    { coin: 'twentyFiveCents', total: 0, pct: 5, value: 0.25, package: '', loose: '' },
    { coin: 'fiftyCents', total: 0, pct: 2, value: 0.5, package: '', loose: '' },
    { coin: 'oneReal', total: 0, pct: 10, value: 1, package: '', loose: '' },
    { coin: 'twoReal', total: 0, pct: 50, value: 2, package: '', loose: '' },
    { coin: 'fiveReal', total: 0, pct: 20, value: 5, package: '', loose: '' },
    { coin: 'tenReal', total: 0, pct: 10, value: 10, package: '', loose: '' },
    { coin: 'twentyReal', total: 0, pct: 5, value: 20, package: '', loose: '' },
    { coin: 'fiftyReal', total: 0, pct: 2, value: 50, package: '', loose: '' },
    { coin: 'hundredReal', total: 0, pct: 1, value: 100, package: '', loose: '' },
];
 
export default function CalculateCash({
    onTotalChange,
    setInfoCalculateCash,
    valueDate,
    infoCalculateCash,
    disabled
}) {
    const [totalTable, setTotalTable] = useState(TOTALS_TABLE);

    useEffect(() => {
        if (infoCalculateCash.length === 0) {
            setTotalTable(
                TOTALS_TABLE.map((item) => ({
                    ...item,
                    package: '',
                    loose: '',
                    total: 0,
                }))
            );
            onTotalChange(0);
        } else {
            const updatedTable = infoCalculateCash.map((item) => ({
                ...item,
                package: item.package ?? 0,
                loose: item.loose ?? 0,
                total: item.total ?? 0,
            }));
            setTotalTable(updatedTable);
            const newTotal = updatedTable.reduce((acc, item) => acc + item.total, 0);
            onTotalChange(newTotal);
        }
    }, [infoCalculateCash, valueDate]);

    const returnTotalTable = (table = totalTable) =>
        formatValue({
            value: table.reduce((acc, item) => acc + item.total, 0),
        });

    const handleInputChange = ({ index, field, value }) => {
        const updatedTotalTable = [...totalTable];
        const item = updatedTotalTable[index];

        const newValue = parseFloat(value.replace(',', '.')) || 0;
        item[field] = newValue;
        item.total =
            (item.package || 0) * (item.pct || 0) * (item.value || 0) +
            (item.loose || 0) * (item.value || 0);

        setTotalTable(updatedTotalTable);
        const newTotal = updatedTotalTable.reduce((acc, item) => acc + item.total, 0);
        onTotalChange(newTotal);
        setInfoCalculateCash(updatedTotalTable);
    };

    const deleteRowTotal = ({ index }) => {
        const updatedTotalTable = [...totalTable];
        const item = updatedTotalTable[index];

        item.loose = 0;
        item.package = 0;
        item.total = 0;

        setTotalTable(updatedTotalTable);

        const newTotal = updatedTotalTable.reduce((acc, item) => acc + item.total, 0);
        onTotalChange(newTotal);
        setInfoCalculateCash(updatedTotalTable);
    };

    return (
        <Section title={'Dinheiro'}>
            <div className={'money-container'}>
                <div className={'money-container-title'}>
                    <div>
                        <p>
                            <i>
                                <strong>{`Total em dinheiro ${returnTotalTable(totalTable)} `}</strong>
                            </i>
                        </p>
                    </div>
                </div>
                <div className={'money-container-body'}>
                    <Table
                        columns={[
                            { name: 'Qtd. pacote', align: 'center' },
                            { name: 'Valor do package', align: 'center' },
                            { name: 'Qtd. solta', align: 'center' },
                            { name: '', align: 'center' },
                            { name: '', align: 'center' },
                        ]}
                    >
                        {totalTable?.map((row, index) => (
                            <tr key={index}>
                                <td className={'field-center'}>
                                    <FieldNumber
                                        minValue={0}
                                        thousandsSeparator={false}
                                        onChange={({ target }) =>
                                            handleInputChange({
                                                field: 'package',
                                                index: index,
                                                value: target.value,
                                            })
                                        }
                                        value={row?.package || ''}
                                        disabled={disabled}
                                    />
                                </td>
                                <td className={'center'}>
                                    <div>
                                        <p>
                                            {formatValue({
                                                value: row.value,
                                            })}
                                        </p>
                                    </div>
                                </td>
                                <td className={'field-center'}>
                                    <FieldNumber
                                        style={{ width: '160px' }}
                                        minValue={0}
                                        thousandsSeparator={false}
                                        onChange={({ target }) =>
                                            handleInputChange({
                                                field: 'loose',
                                                index: index,
                                                value: target.value,
                                            })
                                        }
                                        value={row?.loose || ''}
                                        disabled={disabled}
                                    />
                                </td>
                                <td className={'center'}>
                                    <div className={'d-flex justify-content-between'}>
                                        <p>
                                            {formatValue({
                                                value: row.total,
                                            })}
                                        </p>
                                        <ButtonRemove
                                            disabled={disabled}
                                            onClick={() =>
                                                deleteRowTotal({
                                                    index,
                                                })
                                            }                  
                                        />
                                    </div>
                                </td>
                                <td className={'center'}></td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
        </Section>
    );
}
