import {
    ValidationErrorJobTitleText,
    PermissionsEnum,
    ENUM_SHORTCUT,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldText from '../../components/FieldText';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as JobsTitlesService from '../../services/JobsTitlesService';
import { getJobsTitlesRoute } from './JobsTitles';
import FieldPermissions from '../../components/FieldPermissions';

export default function JobTitleForm() {
    const emptyFormData = {
        name: '',
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [loadingJobTitle, setLoadingJobTitle] = useState(!!uuid);
    const [jobTitleName, setJobTitleName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();

    const canSave = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_JOB_TITLES);
    const canRemove = user.isAdmin || user.roles?.includes(PermissionsEnum.HANDLE_JOB_TITLES);

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchJobTitle() {
        try {
            setLoadingJobTitle(true);
            const jobTitle = await JobsTitlesService.getJobTitle(uuid);
            setJobTitleName(jobTitle.name);
            setFormData({
                ...jobTitle
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Cargo não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch jobTitle', e, null, title);
            }
        } finally {
            setLoadingJobTitle(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchJobTitle();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name: 'Digite o nome do cargo' }));
        }

        return hasError;
    }

    useEffect(() => {
        let shortcutDelete;
        let shortcutConfirm;

        if (uuid) {
            if (canRemove) {
                shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
                    buttonDeleteRef.current?.click();
                });
            }
        }
        if (canSave) {
            shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutConfirm);
        };
    }, []);

    function back() {
        navigate(getJobsTitlesRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                name: formData.name.trim(),
                roles: formData.roles,
            };

            let message;
            if (!uuid) { // new collaborator
                await JobsTitlesService.addJobTitle(body);
                // I18N
                message = 'Cargo salvo com sucesso!';
            } else {
                // I18N
                await JobsTitlesService.editJobTitle(uuid, body);
                message = 'Cargo editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit jobTitle', e, null, title, ValidationErrorJobTitleText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteJobTitle() {
        // I18N
        let title = 'Excluir cargo';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await JobsTitlesService.deleteJobTitle(uuid);
                // I18N
                const message = 'Cargo excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete jobTitle', e, null, title, ValidationErrorJobTitleText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o cargo?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar cargo';
            if (windowTitle && jobTitleName) title += ` - ${jobTitleName}`;
            return title;
        } else {
            return 'Novo cargo';
        }
    }

    function renderPermissions() {
        function onSelected(selection) {
            let roles = formData.roles ?? [];
            if (selection.remove) {
                roles = roles.filter(
                    (item) => !selection.remove.includes(item)
                );
            } else if (selection.add) {
                roles = [...new Set([...roles, ...selection.add])];
            }
            updateFormData({ roles });
        }

        return (
            <div className={'row'}>
                <FieldPermissions
                    groupName={'Caixa'}
                    permissions={[
                        PermissionsEnum.CREATE_SALES, //realizar vendas
                        PermissionsEnum.CREATE_EXCHANGES, //realizar trocas
                        PermissionsEnum.HANDLE_SALES, //gerenciar vendas
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
                <FieldPermissions
                    groupName={'Produto'}
                    permissions={[
                        PermissionsEnum.HANDLE_PRODUCTS, //gerenciar produtos
                        PermissionsEnum.CREATE_STOCK_CHECK, //cadastrar conferencias
                        PermissionsEnum.VALIDATE_STOCK_CHECK, //validar conferencias
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
                <FieldPermissions
                    groupName={'Controle Financeiro'}
                    permissions={[
                        PermissionsEnum.HANDLE_BILLS, //gerenciar contas
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
                <FieldPermissions
                    groupName={'Administrativo'}
                    permissions={[
                        PermissionsEnum.HANDLE_USERS, //gerenciar colaboradores
                        PermissionsEnum.HANDLE_JOB_TITLES, //gerenciar cargos
                        PermissionsEnum.HANDLE_SUPPLIERS, // gerenciar fornecedores
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
                <FieldPermissions
                    groupName={'Fiscal'}
                    permissions={[
                        PermissionsEnum.HANDLE_FISCAL, //gerenciar fiscal
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
                <FieldPermissions
                    groupName={'SAC'}
                    permissions={[
                        PermissionsEnum.HANDLE_CUSTOMERS, //gerenciar clientes
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
                <FieldPermissions
                    groupName={'Comercial'}
                    permissions={[
                        PermissionsEnum.HANDLE_CAMPAIGNS, //gerenciar campanhas
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
                <FieldPermissions
                    groupName={'Loja Virtual'}
                    permissions={[
                        PermissionsEnum.HANDLE_VIRTUAL_STORE, //gerenciar loja virtual
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
                <FieldPermissions
                    groupName={'Compras'}
                    permissions={[
                        PermissionsEnum.HANDLE_SUGGESTIONS, //gerenciar sugestões
                        PermissionsEnum.CHECK_ORDER, //conferir pedidos
                        PermissionsEnum.EDIT_ORDER, // negociar pedidos
                        PermissionsEnum.CONFIRM_ORDER, //confirmar pedidos
                        PermissionsEnum.BILLING_ORDER, // faturar pedidos
                        PermissionsEnum.RECEIVE_ORDER, //receber pedidos
                    ]}
                    select={formData.roles}
                    onSelected={onSelected}
                    readOnly={!canSave}
                />
            </div>
        );
    }

    const renderForm = () => {
        if (loadingJobTitle) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados'}
                        </legend>
                        <div className={'gd field-group'}>
                            <div className={'gd-col-8'}>
                                <FieldText
                                    // I18N
                                    label={'Nome'}
                                    onChange={({ target }) =>
                                        updateFormData({ name: target.value })
                                    }
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Permissões de acesso'}
                        </legend>
                        {renderPermissions()}
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div
                            className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'
                            }`}
                        />
                        <div className={'gd-col gd-col-2'}>
                            {uuid && canRemove && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    style={{ display: `${uuid ? '' : 'none'}` }}
                                    loading={deleteLoading}
                                    onClick={deleteJobTitle}
                                >
                                    {`Excluir [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={
                                        ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                    }
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {`Salvar [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Cargos', route: getJobsTitlesRoute() },
                        { name: uuid ? (loadingJobTitle ? '...' : jobTitleName) : title },
                    ]}
                    backRoute={getJobsTitlesRoute()}
                    hideStore
                >
                </ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getJobTitleFormRoute(uuid) {
    // I18N
    if (uuid) {
        return `/administrativo/cargos/${uuid}`;
    } else {
        return '/administrativo/cargos/novo';
    }
}
