import { useContext, useEffect, useRef, useState } from 'react';
import ScreenHeader from '../../components/logged/ScreenHeader';
import * as BillsToPayService from '../../services/BillsToPayService';
import * as OrdersService from '../../services/OrdersService';
import * as BillingsServices from '../../services/BillingsServices';
import { getBillsToPayRoute } from './BillsToPay';
import FieldText from '../../components/FieldText';
import FieldCurrency, { extractCurrencyNumber, formatCurrency } from '../../components/FieldCurrency';
import FieldDate from '../../components/FieldDate';
import { BillToPayTypeEnum, BillToPayTypeText, ENUM_SHORTCUT } from 'erva-doce-common';
import FieldSelect from '../../components/FieldSelect';
import FieldNumber from '../../components/FieldNumber';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import { useNavigate, useParams } from 'react-router';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { DateTime } from 'luxon';
import OrderDetailHeaderTable from '../order/order-detail/OrderDetailHeaderTable';
import SelectAccountsModal from '../../components/SelectAccountsModal';
import FieldContact from '../../components/FieldContact';

const INITIAL_STATE = {
    name: '',
    description: '',
    value: '',
    issueDate: '',
    dueDate: '',
    type: '',
    totalInstallments: '',
    dueDayInstallment: '',
    numberDocument: '',
};

export default function BillsToPayForm() {
    const title = 'Contas a Pagar';
    const { uuid } = useParams();
    const navigate = useNavigate();
    const isNew = !uuid;
    const { backendConnectionError, setInfoModal, addHotkey, removeHotkey } = useContext(EnvironmentContext);

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [formError, setFormError] = useState({});
    const [order, setOrder] = useState({});
    const [showSelectAccount, setShowSelectAccount] = useState(false);
    const createPayableAccountBtnRef = useRef();
    const deleteAccountBtnRef = useRef();
    const writeOffAccountBtnRef = useRef();

    const updateFormData = (value) => {
        setFormData(state => ({
            ...state,
            ...value,
        }));
    };

    const hasValidationErrors = () => {
        let hasError = false;
        setFormError({});

        if (!formData.name) {
            hasError = true;
            setFormError(state => ({ ...state, name: 'Preencha corretamente o campo' }));
        }

        if (!formData.numberDocument) {
            hasError = true;
            setFormError(state => ({ ...state, numberDocument: 'Preencha corretamente o campo' }));
        }

        if (!formData.creditor?.id && !formData.supplier?.id) {
            hasError = true;
            setFormError(state => ({ ...state, creditor: 'Preencha corretamente o campo' }));
        }

        if (!formData.value) {
            hasError = true;
            setFormError(state => ({ ...state, value: 'Preencha corretamente o campo' }));
        }

        if (!formData.issueDate) {
            hasError = true;
            setFormError(state => ({ ...state, issueDate: 'Preencha corretamente o campo' }));
        }

        if (!formData.dueDate) {
            hasError = true;
            setFormError(state => ({ ...state, dueDate: 'Preencha corretamente o campo' }));
        }

        if (!formData.type) {
            hasError = true;
            setFormError(state => ({ ...state, type: 'Preencha corretamente o campo' }));
        } else {
            if (formData.type === BillToPayTypeEnum.INSTALLS) {
                if (!formData.totalInstallments) {
                    hasError = true;
                    setFormError(state => ({ ...state, totalInstallments: 'Preencha corretamente o campo' }));
                }

                if (!formData.dueDayInstallment) {
                    hasError = true;
                    setFormError(state => ({ ...state, dueDayInstallment: 'Preencha corretamente o campo' }));
                }
            }

            if (formData.type === BillToPayTypeEnum.FIXED) {
                if (!formData.dueDayInstallment) {
                    hasError = true;
                    setFormError(state => ({ ...state, dueDayInstallment: 'Preencha corretamente o campo' }));
                }
            }
        }

        return hasError;
    };

    const save = async () => {
        if (hasValidationErrors()) return;

        try {
            setIsLoading(true);
            await BillsToPayService.create({
                ...formData,
                value: extractCurrencyNumber(formData.value),
                creditor: formData?.creditor?.id,
                supplier: formData?.supplier?.id,
            });

            setInfoModal({
                title,
                message: 'Nova conta criada com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToPayRoute()),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save billToPay', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const update = async (accountId) => {
        try {
            setIsLoading(true);
            await BillsToPayService.update(uuid, {
                payed: true,
                account: accountId,
            });

            setInfoModal({
                title,
                message: 'Baixa em conta com sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToPayRoute()),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save billToPay', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const destroy = async () => {
        try {
            setIsLoading(true);
            await BillsToPayService.destroy(uuid);

            setInfoModal({
                title,
                message: 'Conta excluída com sucesso!',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: () => navigate(getBillsToPayRoute()),
            });

        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to save billToPay', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTypeOptions = () => {
        const options = [];
        for (const option in BillToPayTypeEnum) {
            options.push({
                id: option,
                value: BillToPayTypeText(option),
            });
        }

        return options;
    };

    const fetchInfo = async () => {
        try {
            const res = await BillsToPayService.getByUUID(uuid);

            setFormData({
                ...res,
                issueDate: DateTime.fromISO(res.issueDate).toFormat('yyyy-MM-dd'),
                dueDate: DateTime.fromISO(res.dueDate).toFormat('yyyy-MM-dd'),
                value: formatCurrency(res.value.toFixed(2)),
                creditor: res.creditor ? {
                    id: res.creditor.uuid,
                    value: res.creditor.fantasyName,
                } : null,
                supplier: res.supplier ? {
                    id: res.supplier.uuid,
                    value: res.supplier.fantasyName,
                } : null,
            });

            if (res.orderBilling) {
                const orderBillingData = await BillingsServices.getBillingById(res.orderBillingUUID);
                const orderData = await OrdersService.getOrderById(orderBillingData.order);

                setOrder(orderData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (uuid) {
            fetchInfo();
        }
    }, [uuid]);

    useEffect(() => {
        const shortcutCreatePrimary = addHotkey(ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY, () => {
            if (isNew && createPayableAccountBtnRef.current) {
                createPayableAccountBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutCreatePrimary);
        };
    }, []);

    useEffect(() => {
        const shortcutDelete = addHotkey(ENUM_SHORTCUT.SHORTCUT_DELETE, () => {
            if (!formData.orderBilling && deleteAccountBtnRef.current) {
                deleteAccountBtnRef.current.click();
            }
        });

        const shortcutConfirm = addHotkey(ENUM_SHORTCUT.SHORTCUT_CONFIRM, () => {
            if (!formData.payed && writeOffAccountBtnRef.current) {
                writeOffAccountBtnRef.current.click();
            }
        });

        return () => {
            removeHotkey(shortcutDelete);
            removeHotkey(shortcutConfirm);
        };

    }, [formData]);

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '/' },
                        { name: title, route: getBillsToPayRoute() },
                        { name: uuid || 'Novo', route: getBillsToPayFormRoute(uuid) },
                    ]}
                    backRoute={getBillsToPayRoute()}
                    hideStore
                />
                {
                    order?.id && (
                        <div className={'row mb-16'}>
                            <div className={'col-12'}>
                                <OrderDetailHeaderTable
                                    data={order}
                                    virtualTotal={order.total}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    )
                }
                <div className={'row'}>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Nome'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ name: target.value })}
                            value={formData?.name}
                            validationError={formError?.name}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldText
                            label={'Descrição'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ description: target.value })}
                            value={formData?.description}
                            validationError={formError?.description}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-4'}>
                        <FieldContact
                            label={'Credor'}
                            type={'text'}
                            select={formData?.creditor || formData?.supplier}
                            onSelected={(e) => updateFormData({ creditor: e?.isContact ? e : null, supplier: e?.isSupplier ? e : null })}
                            validationError={formError?.creditor}
                            disabled={!isNew}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <FieldText
                            label={'Número do título'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ numberDocument: target.value })}
                            value={formData?.numberDocument}
                            validationError={formError?.numberDocument}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldCurrency
                            label={'Valor da despesa'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ value: formatCurrency(target.value) })}
                            value={formData?.value}
                            validationError={formError?.value}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldDate
                            label={'Data de emissão'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ issueDate: target.value })}
                            value={formData?.issueDate}
                            validationError={formError?.issueDate}
                            disabled={!isNew}
                        />
                    </div>
                    <div className={'col-2'}>
                        <FieldDate
                            label={'Data de validade'}
                            type={'text'}
                            onChange={({ target }) => updateFormData({ dueDate: target.value })}
                            value={formData?.dueDate}
                            validationError={formError?.dueDate}
                            disabled={!isNew}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <FieldSelect
                            label={'Tipo de cobrança'}
                            options={handleTypeOptions()}
                            onChange={({ target }) => {
                                let type = target.value;

                                if (target === '-1') {
                                    type = null;
                                }

                                updateFormData({ type, totalInstallments: null, dueDayInstallment: null });
                            }}
                            value={formData?.type || '-1'}
                            validationError={formError?.type}
                            placeholder={'Selecione'}
                            disableDefaultOption={false}
                            disabled={!isNew}
                        />
                    </div>
                    {
                        formData.type === BillToPayTypeEnum.INSTALLS && (
                            <div className={'col-2'}>
                                <FieldNumber
                                    label={'Quantidade de parcelas'}
                                    onChange={({ target }) => updateFormData({ totalInstallments: target.value })}
                                    value={formData?.totalInstallments}
                                    validationError={formError?.totalInstallments}
                                    thousandsSeparator={false}
                                    disabled={!isNew}
                                />
                            </div>
                        )
                    }
                    {
                        (formData.type === BillToPayTypeEnum.FIXED || formData.type === BillToPayTypeEnum.INSTALLS) && (
                            <div className={'col-2'}>
                                <FieldNumber
                                    label={'Dia de vencimento por mês'}
                                    onChange={({ target }) => updateFormData({ dueDayInstallment: target.value })}
                                    value={formData?.dueDayInstallment}
                                    validationError={formError?.dueDayInstallment}
                                    thousandsSeparator={false}
                                    disabled={!isNew}
                                />
                            </div>

                        )
                    }
                </div>
                <div className={'row d-flex justify-content-end'}>
                    <div className={'col-6 align-right update-purchase'}>
                        {
                            isNew ? (
                                <Button
                                    ref={createPayableAccountBtnRef}
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    className={'ml-10 w-25'}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    onClick={save}
                                >
                                    {`Criar conta [${ENUM_SHORTCUT.SHORTCUT_CREATE_PRIMARY}]`}
                                </Button>
                            ) : (
                                <>
                                    {
                                        formData && !formData.orderBilling && (
                                            <Button
                                                ref={deleteAccountBtnRef}
                                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                                className={'ml-10'}
                                                color={ButtonColor.BUTTON_COLOR_GRAY}
                                                onClick={destroy}
                                            >
                                                {`Excluir conta [${ENUM_SHORTCUT.SHORTCUT_DELETE}]`}
                                            </Button>
                                        )
                                    }
                                    {
                                        formData && !formData.payed && (
                                            <Button
                                                ref={writeOffAccountBtnRef}
                                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                                className={'ml-10'}
                                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                                onClick={() => setShowSelectAccount(true)}
                                            >
                                                {`Dar baixa em conta [${ENUM_SHORTCUT.SHORTCUT_CONFIRM}]`}
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <SelectAccountsModal
                show={showSelectAccount}
                onCancel={() => {
                    setShowSelectAccount(false);
                }}
                onConfirm={(e) => {
                    setShowSelectAccount(false);
                    update(e);
                }}
            />
        </>
    );
}

export function getBillsToPayFormRoute(uuid) {
    return `/financeiro/contas-a-pagar/${uuid ? uuid : 'novo'}`;
}
